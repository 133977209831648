<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m99.582 9.4727l-30.93 85.852c-1.3203 3.6719-5.375 5.5781-9.0508 4.2539-1.8164-0.65234-3.2891-2.0273-4.0703-3.793l-15.727-35.59-35.59-15.727c-3.5703-1.5781-5.1875-5.7539-3.6094-9.3242 0.78125-1.7695 2.2539-3.1406 4.0703-3.7969l85.855-30.926c3.6758-1.3242 7.7266 0.58203 9.0508 4.2539 0.55859 1.5508 0.55859 3.2461 0 4.7969z" fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
