import usersApi from '@/api/usersApi.js'

export default {
  namespaced: true,

  state: {
    token: '',
    token_expire_date: ''
  },

  actions: {
    async storeToken ({ commit }, expirationDate) {
      const response = await usersApi.storeToken(expirationDate)
      commit('storeToken', response)
    },

    async deleteToken ({ commit }) {
      await usersApi.deleteToken()
      commit('deleteToken')
    },

    async loadToken ({ commit }) {
      const response = await usersApi.loadToken()
      commit('loadToken', response)
    }
  },

  mutations: {
    storeToken (state, response) {
      state.token = response.token
      state.token_expire_date = response.date
    },

    deleteToken (state) {
      state.token = ''
      state.token_expire_date = ''
    },

    loadToken (state, response) {
      state.token = response.token
      state.token_expire_date = response.date
    }
  }
}
