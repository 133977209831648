<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 18 19"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="M4.17149 11.7033L2.79802 11.8998C2.12104 11.9966 1.6 12.5816 1.6 13.2855C1.6 14.0587 2.2268 14.6855 3 14.6855H15C15.7732 14.6855 16.4 14.0587 16.4 13.2855C16.4 12.5816 15.879 11.9966 15.202 11.8998L13.8286 11.7033V6.42857C13.8286 3.76182 11.6668 1.6 9.00007 1.6C6.33332 1.6 4.17149 3.76182 4.17149 6.42857V11.7033ZM2.57149 6.42857C2.57149 2.87817 5.44966 0 9.00007 0C12.5505 0 15.4286 2.87817 15.4286 6.42857V10.3159C16.8824 10.5239 18 11.7742 18 13.2855C18 14.9424 16.6569 16.2855 15 16.2855H3C1.34315 16.2855 0 14.9424 0 13.2855C0 11.7741 1.11762 10.5238 2.57149 10.3159V6.42857Z" />
      <path d="M7.6571 16.2856C7.6571 16.6332 8.0825 17.1999 8.99996 17.1999C9.91741 17.1999 10.3428 16.6332 10.3428 16.2856H11.9428C11.9428 17.8316 10.4494 18.7999 8.99996 18.7999C7.55047 18.7999 6.0571 17.8316 6.0571 16.2856H7.6571Z" />
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
