<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m88.211 77.512-35.398-57.723c-1.5469-2.5234-4.082-2.5234-5.6289 0l-35.395 57.723c-1.5469 2.5234-0.39062 4.5898 2.5703 4.5898h71.285c2.957 0 4.1133-2.0625 2.5664-4.5898zm-38.211-1.3789c-2.3203 0-4.1992-1.8789-4.1992-4.1992 0-2.3164 1.8789-4.1953 4.1992-4.1953s4.1992 1.8789 4.1992 4.1992c0 2.3164-1.8789 4.1953-4.1992 4.1953zm4.1992-15.871c0 2.3086-1.8906 4.1992-4.1992 4.1992s-4.1992-1.8906-4.1992-4.1992v-21.223c0-2.3086 1.8906-4.1992 4.1992-4.1992s4.1992 1.8906 4.1992 4.1992z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
