<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 17 19"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path  d="M14 18.2249H2.99999C1.56405 18.2249 0.399994 17.0608 0.399994 15.6249V2.9999C0.399994 1.56396 1.56405 0.399902 2.99999 0.399902H9.94753C10.63 0.399902 11.2851 0.66824 11.7715 1.14701L15.8239 5.13615C16.3204 5.62487 16.6 6.29239 16.6 6.98905V15.6249C16.6 17.0608 15.4359 18.2249 14 18.2249ZM2.99999 1.9999C2.44771 1.9999 1.99999 2.44762 1.99999 2.9999V15.6249C1.99999 16.1772 2.44771 16.6249 2.99999 16.6249H14C14.5523 16.6249 15 16.1772 15 15.6249V6.98905C15 6.7211 14.8925 6.46436 14.7015 6.2764L10.649 2.28725C10.462 2.10311 10.21 1.9999 9.94753 1.9999H2.99999Z"/>
      <path  d="M9.54496 3.84058C9.98679 3.84058 10.345 4.19875 10.345 4.64058V6.58276H12.3307C12.7725 6.58276 13.1307 6.94094 13.1307 7.38276C13.1307 7.82459 12.7725 8.18276 12.3307 8.18276H9.54496C9.10314 8.18276 8.74496 7.82459 8.74496 7.38276V4.64058C8.74496 4.19875 9.10314 3.84058 9.54496 3.84058Z"/>
      <path  d="M3.63748 10.9375C3.63748 10.4956 3.99565 10.1375 4.43748 10.1375H12.5625C13.0043 10.1375 13.3625 10.4956 13.3625 10.9375C13.3625 11.3793 13.0043 11.7375 12.5625 11.7375H4.43748C3.99565 11.7375 3.63748 11.3793 3.63748 10.9375Z" />
      <path  d="M3.63748 14.1875C3.63748 13.7456 3.99565 13.3875 4.43748 13.3875H12.5625C13.0043 13.3875 13.3625 13.7456 13.3625 14.1875C13.3625 14.6293 13.0043 14.9875 12.5625 14.9875H4.43748C3.99565 14.9875 3.63748 14.6293 3.63748 14.1875Z" />
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
