import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'status',
    component: () => import('@/views/status/index.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'status.overview',
        component: () => import('@/views/status/status-overview.vue'),
        props: true
      },
      {
        path: 'history',
        name: 'status.history',
        component: () => import('@/views/status/status-history.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
