<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path d="M7.25168 1.14697C8.01369 -0.224651 9.9863 -0.224653 10.7483 1.14697L17.3493 13.0287C18.0899 14.3618 17.1259 16 15.601 16H2.39903C0.87406 16 -0.089876 14.3618 0.650714 13.0287L7.25168 1.14697Z" fill="#1B1823"/>
  </svg>

</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
