<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m88.211 47.746l-9.5977-23.168c-4.9727-12.004-18.859-17.762-30.867-12.789l-23.168 9.6016c-12.008 4.9688-17.762 18.859-12.789 30.867l9.5977 23.172c4.9727 12.004 18.863 17.754 30.871 12.781l23.172-9.5977c12-4.9727 17.754-18.859 12.781-30.867zm-15.336 24.707l-23.168 9.5977c-2.0586 0.85156-4.2188 1.2812-6.4336 1.2812-6.9219 0-13.098-4.1055-15.73-10.461l-9.5977-23.168c-1.7266-4.1641-1.7148-8.7695 0.027344-12.973 1.7383-4.2031 4.9883-7.4648 9.1484-9.1875l23.172-9.6016c2.0508-0.85156 4.2148-1.2773 6.4219-1.2773 6.9258 0 13.102 4.1055 15.734 10.461l9.5977 23.164c3.582 8.6484-0.53125 18.586-9.1719 22.164z"/>
      <path d="m75.559 52.043l-2.5508-6.1602-10.32 4.2734-3.8242-9.2383 10.316-4.2773-2.5508-6.1602-10.312 4.2734-4.2773-10.312-6.1602 2.5508 4.2734 10.316-9.2383 3.8242-4.2734-10.312-6.1562 2.5508 4.2734 10.316-10.316 4.2734 2.5508 6.1602 10.32-4.2734 3.8242 9.2383-10.316 4.2734 2.5508 6.1602 10.32-4.2734 4.2734 10.316 6.1602-2.5508-4.2734-10.316 9.2344-3.8242 4.2695 10.32 6.1602-2.5547-4.2734-10.316zm-28.262 4.4883l-3.8242-9.2383 9.2344-3.8242 3.8242 9.2422z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
