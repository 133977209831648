<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m73.336 23.836-23.336 23.336-23.336-23.336-2.8281 2.8281 23.336 23.336-23.336 23.336 2.8281 2.8281 23.336-23.336 23.336 23.336 2.8281-2.8281-23.336-23.336 23.336-23.336z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
