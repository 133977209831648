<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m73.535 54.332h-29.469c0 0.13281 0.066406 0.26562 0.066406 0.39844v1.6016h27.465v4.668h-27.465v2h29.465l0.003906-8.668z"/>
      <path d="m42.133 56.332v-1.6016c0-0.13281 0-0.26562-0.066406-0.39844-0.19922-1.668-1.3984-3.0664-3-3.4648v-2.7344c0-2.668-2.1328-4.8008-4.7344-4.8008-2.6016 0-4.7344 2.1328-4.7344 4.8008v2.7344c-1.7344 0.39844-3.0664 2-3.0664 3.8672v11.867h15.668v-10.27zm-10.598-8.2656c0-1.5352 1.2656-2.8008 2.7344-2.8008 1.5352 0 2.7344 1.2656 2.7344 2.8008v2.6016h-5.5352l-0.003906-2.6016zm3.7305 13.801h-2v-6.3984h2z"/>
      <path d="m85.668 18.266h-71.332c-2 0-3.668 1.668-3.668 3.668v56.066c0 2 1.668 3.668 3.668 3.668h71.332c2 0 3.668-1.668 3.668-3.668l-0.003907-56.066c0-2-1.6641-3.668-3.6641-3.668zm-50.934 8.7344c0 1.1328-0.93359 2.0664-2.0664 2.0664-1.1328 0-2-0.93359-2-2.0664s0.93359-2.0664 2.0664-2.0664 2 0.93359 2 2.0664zm-6.668 0c0 1.1328-0.93359 2.0664-2.0664 2.0664s-2-0.93359-2-2.0664 0.93359-2.0664 2.0664-2.0664c1.1328 0 2 0.93359 2 2.0664zm-8.668-2.0664c1.1328 0 2.0664 0.93359 2.0664 2.0664s-0.93359 2.0664-2.0664 2.0664c-1.1328 0-2.0664-0.93359-2.0664-2.0664s0.93359-2.0664 2.0664-2.0664zm67.934 53.133c0 0.93359-0.73438 1.668-1.668 1.668h-71.332c-0.93359 0-1.668-0.73438-1.668-1.668l0.003907-43.398h74.668z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
