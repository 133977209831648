<template>
  <validation-provider :rules="rules"
                       :vid="vid"
                       :name="name"
                       :mode="validationInteractionMode"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-date-picker"
         :class="getClasses(classes)">
      <label class="label"
             v-if="label"
             @click="open">{{ label }}</label>

      <flat-pickr :id="id"
                  ref="input"
                  class="input"
                  :name="name"
                  :disabled="disabled"
                  :placeholder="placeholder"
                  :config="config"
                  v-model="date"
                  @on-close="$emit('close', $event)" />

        <div class="error"
             v-show="showErrors && errors.length > 0">
          {{ errors[0] }}
        </div>
      <div class="error"
             v-show="showErrors && customErrors.length > 0">
          {{ customErrors[0] }}
        </div>

        <div class="description"
             v-if="description">
          {{ description }}
        </div>
    </div>
  </validation-provider>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { cloneObject } from '@/services/utils.js'

export default {
  props: {
    config: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    },

    id: {
      required: false,
      type: String
    },

    vid: {
      required: false,
      type: String
    },

    value: {
      required: true
    },

    name: {
      required: false,
      type: String
    },

    label: {
      required: false,
      type: String
    },

    placeholder: {
      required: false,
      type: String,
      default: ''
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    description: {
      required: false,
      type: String
    },

    rules: {
      required: false,
      type: [String, null], // todo: why null?
      default: ''
    },

    validationInteractionMode: {
      required: false,
      type: String,
      default: 'lazy'
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    },

    customErrors: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  components: {
    flatPickr
  },

  data () {
    return {
      date: '',
      flatpickrConfig: {}
    }
  },

  methods: {
    getClasses (validationClasses) {
      return {
        ...validationClasses,
        'has-validation-rules': !!this.rules,
        'disabled': this.disabled
      }
    },

    open () {
      this.$refs.input.fp.open()
    },

    focus () {
      this.$refs.input.focus()
    }
  },

  watch: {
    value: {
      handler: function () {
        this.date = cloneObject(this.value.toString())
      },
      immediate: true
    },

    date (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
  .base-date-picker {
    position: relative;
    margin-bottom: 20px;

    .input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      background: #fff;
      border: 1px solid map-get($colors, gray-3);
      border-radius: 4px;
      outline: none;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: map-get($colors, gray-1);
      transition: all 0.2s;

      &:focus {
        border: 1px solid map-get($colors, purple-1);
      }

      &::placeholder {
        text-align: start;
      }
    }

    &.has-validation-rules {
      &.invalid .input {
        border-color: map-get($colors, red-2);
      }

      &.valid .input {
        border-color: map-get($colors, success);
      }
    }

    &.disabled .input {
      background-color: #f5f5f5;
    }

    .label {
      display: block;
      margin-bottom: 6px;
      color: map-get($colors, gray-1);
      font-size: 14px;
      font-weight: 600;
    }

    .error {
      margin-top: 4px;
      color: map-get($colors, red-2)
    }

    .description {
      margin-top: 4px;
      font-size: 12px;
      line-height: 18px;
      color: map-get($colors, gray-1);
    }
  }
</style>
