// @see https://github.com/JosephusPaye/Keen-UI/blob/master/src/helpers/util.js

/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject (obj) {
  return obj !== null && typeof obj === 'object'
}

/**
 * Check if two values are loosely equal - that is,
 * if they are plain objects, do they have the same shape?
 */
export function looseEqual (a, b) {
  // eslint-disable-next-line eqeqeq
  return a == b || (
    isObject(a) && isObject(b) ? JSON.stringify(a) === JSON.stringify(b) : false
  )
}

/**
 * Check if a val exists in arr using looseEqual comparison
 */
export function looseIndexOf (arr, val) {
  for (let i = 0; i < arr.length; i++) {
    if (looseEqual(arr[i], val)) {
      return i
    }
  }

  return -1
}

export function downloadFile (url, filename) {
  axios({
    method: 'get',
    url: url,
    responseType: 'arraybuffer'
  }).then(function (response) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename)
    } else {
      let blob = new Blob([response.data])
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    }
  })
}

export function getSortedArrayOfObjects (arr, key, direction = 1) {
  return arr.slice().sort((a, b) => {
    if (a[key] === null) {
      return 1
    }

    if (b[key] === null) {
      return -1
    }

    return a[key].toLowerCase().localeCompare(b[key].toLowerCase()) * direction
  })
}

export function paginateArrayOfObjects (arr, limit, offset) {
  return arr.filter((item, index) => {
    return index >= offset && index < (offset + limit)
  })
}

export function num2str (n, text_forms) {
  n = Math.abs(n) % 100; let n1 = n % 10
  if (n > 10 && n < 20) { return text_forms[2] }
  if (n1 > 1 && n1 < 5) { return text_forms[1] }
  if (n1 === 1) { return text_forms[0] }

  return text_forms[2]
}

export function cloneObject (object) {
  return JSON.parse(JSON.stringify(object))
}

export function secondsToHms (d) {
  d = Number(d)
  let h = Math.floor(d / 3600)
  let m = Math.floor(d % 3600 / 60)
  let s = Math.floor(d % 3600 % 60)

  let hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
  let mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
  let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''

  return hDisplay + mDisplay + sDisplay
}

export function getIpResolveUrl (ip) {
  return `https://www.ip2location.com/demo/${ip}`
}

export function getUrlParts (url) {
  const link = document.createElement('a')
  link.setAttribute('href', url)

  return link
}

// @see https://stackoverflow.com/questions/316781/how-to-build-query-string-with-javascript
export function composeQueryString (params) {
  let esc = encodeURIComponent

  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&')
}
