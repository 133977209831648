<template>
    <div>
      You must confirm your email first.
    </div>
</template>

<script>
export default {
  name: 'AccountUnverified'
}
</script>
