<template>
  <div class="BaseSelectTag">
    <label class="label"
           v-if="label">{{ label }}</label>

    <div class="options">
      <div v-for="option in options"
           :key="getOptionValue(option)"
           class="option"
           :class="{ active: optionSelected(option) }">
        <div class="text" @click="toggle(option)">{{ getOptionLabel(option) }}</div>
        <div v-if="canBeDeleted"
             class="delete"
             v-hint="{ content: deleteHint }"
             @click="$emit('delete', option)"></div>
      </div>
    </div>

    <div class="description"
         v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import { looseEqual, looseIndexOf } from '@/services/utils'
import { cloneObject } from '@/services/utils.js'
import tippy from 'tippy.js'

export default {
  model: {
    prop: 'selectedItems',
    event: 'update'
  },

  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    selectedItems: {
      required: false
    },

    label: {
      required: false,
      type: String
    },

    description: {
      required: false,
      type: String
    },

    multiple: {
      required: false,
      default: false
    },

    keys: {
      required: false,
      default: () => {
        return {
          value: 'value',
          label: 'label'
        }
      }
    },

    canBeEmpty: {
      required: false,
      type: Boolean,
      default: false
    },

    canBeDeleted: {
      required: false,
      type: Boolean,
      default: false
    },

    deleteHint: {
      required: false,
      type: String,
      default: null
    },

    returnValueOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedItemsCopy: null
    }
  },

  methods: {
    toggle (option) {
      if (this.multiple) {
        let index = looseIndexOf(this.selectedItemsCopy, option)

        if (index === -1) {
          this.selectedItemsCopy.push(option)
        } else {
          this.selectedItemsCopy.splice(index, 1)
        }
      } else {
        if (this.optionSelected(option) && this.canBeEmpty) {
          this.selectedItemsCopy = null
        } else {
          this.selectedItemsCopy = cloneObject(option)
        }
      }

      this.$emit('update', this.selectedItemsCopy)
    },

    optionSelected (option) {
      if (this.multiple) {
        return looseIndexOf(this.selectedItemsCopy, option) > -1
      }

      return looseEqual(this.selectedItemsCopy, option)
    },

    getOptionLabel (option) {
      return option[this.keys.label]
    },

    getOptionValue (option) {
      return option[this.keys.value]
    },

    setupDeleteHint () {
      tippy(this.$refs.icon, {
        content: this.deleteHint,
        arrow: true
      })
    }
  },

  watch: {
    selectedItems: {
      handler: function (items) {
        this.selectedItemsCopy = cloneObject(items)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseSelectTag {
    margin-bottom: 20px;

    .label {
      display: block;
      color: map-get($colors, gray-1);
      margin-bottom: 10px;
      opacity: .8;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: .05em;
      text-transform: uppercase;
      -webkit-font-feature-settings: "ss04" off;
      font-feature-settings: "ss04" off;
    }

    .options {
      display: flex;
      flex-wrap: wrap;
    }

    .option {
      @include inline-flex-center;

      flex-shrink: 0;

      text-align: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: map-get($colors, gray-1);

      margin: 0 8px 8px 0;
      height: 30px;

      background-color: #fff;
      border: 1px solid #e8ebec;
      border-radius: 30px;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

      cursor: pointer;
      user-select: none;

      transition: all .2s;

      &:hover {
        border: 1px solid map-get($colors, purple-1);
        color: map-get($colors, purple-1);
      }

      &.active {
        border: 1px solid map-get($colors, purple-1);
        background-color: map-get($colors, purple-1);
        color: #fff;
        box-shadow: 0 2px 4px rgba(25,34,29,.05);
      }

      .text {
        padding: 0 16px;
        line-height: 28px;
      }

      .delete {
        padding: 0 10px;
        line-height: 28px;

        &:before {
          content: "✕";
        }
      }
    }

    .description {
      margin-top: 4px;
      font-size: 14px;
      line-height: 21px;
      color: map-get($colors, gray-1);
    }
  }
</style>
