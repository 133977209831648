<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 18 18"
       @click="$emit('click')">
    <path d="M3 6L3.74891 16.0241C3.78764 16.5736 4.26327 17 4.83709 17H13.1629C13.7362 17 14.2118 16.5736 14.2511 16.0241L15 6" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M1 4V3.66833C1 3.29933 1.51314 3 2.14571 3H5.46922C6.07502 3 6.60307 2.58771 6.75 2V2C6.89693 1.41229 7.42498 1 8.03078 1H9.96922C10.575 1 11.1031 1.41229 11.25 2V2C11.3969 2.58771 11.925 3 12.5308 3H15.8543C16.4869 3 17 3.29933 17 3.66833V4" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path opacity="0.5" d="M7 13V8" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M11 13V8" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
