<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m94 44.102c0 2.1992-1.8008 4-4 4h-13c-2.1992 0-4-1.8008-4-4 0-2.1992 1.8008-4 4-4h13c2.3008 0 4 1.7969 4 4zm-9 11.898h-8c-2.1992 0-4 1.8008-4 4s1.8008 4 4 4h8c2.1992 0 4-1.8008 4-4-0.10156-2.1992-1.8008-4-4-4zm9.6016-31.699h-17.602c-2.1992 0-4 1.8008-4 4s1.8008 4 4 4h17.602c2.1992 0 4-1.8008 4-4s-1.8008-4-4-4zm-28.902 2.0977v35.398c0 4.8008-3.8984 8.6992-8.6992 8.6992h-8.8008v10c0 1.6016-1.8984 2.3984-3 1.1992l-11.199-11.094s0-0.10156-0.10156-0.10156h-23.797c-4.8008 0-8.6992-3.8984-8.6992-8.6992l-0.003906-35.402c0-4.8008 3.8984-8.6992 8.6992-8.6992h47c4.7031 0 8.6016 3.9023 8.6016 8.6992zm-41.801 17.703c0-2.1992-1.8008-4-4-4-2.1992 0-4 1.8008-4 4 0 2.1992 1.8008 4 4 4 2.3008 0 4-1.8008 4-4zm13.602 0c0-2.1992-1.8008-4-4-4s-4 1.8008-4 4c0 2.1992 1.8008 4 4 4 2.3008 0 4-1.8008 4-4zm13.602 0c0-2.1992-1.8008-4-4-4-2.1992 0-4 1.8008-4 4 0 2.1992 1.8008 4 4 4 2.2969 0 4-1.8008 4-4z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
