<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 20 13"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="M10.3328 0.208626C10.677 0.157625 11.0148 0.335043 11.1681 0.647451L13.6485 5.69999H19C19.4418 5.69999 19.8 6.05816 19.8 6.49999C19.8 6.94182 19.4418 7.29999 19 7.29999H13.15C12.8449 7.29999 12.5663 7.12643 12.4319 6.85253L10.8352 3.60002L9.43951 12.1292C9.38331 12.4726 9.11151 12.7404 8.76725 12.7914C8.42298 12.8424 8.08524 12.6649 7.93188 12.3525L5.45154 7.29999H1.00001C0.558184 7.29999 0.200012 6.94182 0.200012 6.49999C0.200012 6.05816 0.558184 5.69999 1.00001 5.69999H5.95001C6.25513 5.69999 6.53369 5.87355 6.66815 6.14745L8.26483 9.39996L9.66051 0.870799C9.71671 0.527345 9.98851 0.259627 10.3328 0.208626Z" />
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
