<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 64 64"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m32 4a28 28 0 1 0 28 28 28.032 28.032 0 0 0 -28-28zm0 52a24 24 0 1 1 24-24 24.027 24.027 0 0 1 -24 24z"/><path d="m32.3 14a10.73 10.73 0 0 0 -11.028 10.727h4a6.673 6.673 0 0 1 2.038-4.822 6.728 6.728 0 1 1 7.556 10.91 8.545 8.545 0 0 0 -4.866 7.703v3.664h4v-3.664a4.527 4.527 0 0 1 2.573-4.085 10.737 10.737 0 0 0 6.15-10.008 10.857 10.857 0 0 0 -10.423-10.425z"/><circle cx="32" cy="46" r="2"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
