<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <path d="M23 18C23 19.1046 22.1046 20 21 20L3 20C1.89543 20 1 19.1046 1 18V6.00003C1 4.89546 1.89543 4.00003 3 4.00003L21 4C22.1046 4 23 4.89543 23 6V18Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M4.99465 8.00002C4.44237 8.00298 3.99706 8.45308 4.00002 9.00536C4.00297 9.55764 4.45308 10.0029 5.00535 9.99999L4.99465 8.00002ZM17.0137 9.93574C17.5659 9.93278 18.0113 9.48268 18.0083 8.9304C18.0053 8.37812 17.5552 7.93281 17.003 7.93577L17.0137 9.93574ZM5.29127 9.99846C5.84354 9.99551 6.28886 9.5454 6.2859 8.99313C6.28295 8.44085 5.83284 7.99554 5.28056 7.99849L5.29127 9.99846ZM7.06752 7.98893C6.51524 7.99189 6.06993 8.44199 6.07288 8.99427C6.07584 9.54654 6.52594 9.99186 7.07822 9.9889L7.06752 7.98893ZM12.0817 9.96213C12.634 9.95917 13.0793 9.50907 13.0763 8.95679C13.0734 8.40452 12.6233 7.9592 12.071 7.96216L12.0817 9.96213ZM13.8579 7.9526C13.3057 7.95555 12.8603 8.40566 12.8633 8.95793C12.8663 9.51021 13.3164 9.95552 13.8686 9.95257L13.8579 7.9526ZM14.9408 9.94683C15.4931 9.94387 15.9384 9.49377 15.9354 8.94149C15.9325 8.38922 15.4824 7.9439 14.9301 7.94686L14.9408 9.94683ZM16.7171 7.9373C16.1648 7.94025 15.7195 8.39036 15.7224 8.94263C15.7254 9.49491 16.1755 9.94022 16.7278 9.93727L16.7171 7.9373ZM5.00535 9.99999L5.29127 9.99846L5.28056 7.99849L4.99465 8.00002L5.00535 9.99999ZM7.07822 9.9889L12.0817 9.96213L12.071 7.96216L7.06752 7.98893L7.07822 9.9889ZM13.8686 9.95257L14.9408 9.94683L14.9301 7.94686L13.8579 7.9526L13.8686 9.95257ZM16.7278 9.93727L17.0137 9.93574L17.003 7.93577L16.7171 7.9373L16.7278 9.93727Z" fill="white"/>
    <path d="M5 16H10" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M15 16H19.25" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1 8 6 8"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
