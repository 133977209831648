<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 16 16"
       fill="none"
       @click="$emit('click')">
    <g opacity="0.6">
      <path d="M2 11.4615V2.23077C2 1.55103 2.53726 1 3.2 1H9.2M6.2 15H12.8C13.4627 15 14 14.449 14 13.7692V5.23077C14 4.55103 13.4627 4 12.8 4H6.2C5.53726 4 5 4.55104 5 5.23077V13.7692C5 14.449 5.53726 15 6.2 15Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
