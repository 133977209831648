<template>
  <validation-provider :rules="rules"
                       :vid="vid"
                       :name="name"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-dropdown"
         :class="getClasses(classes)">
    <label class="label"
           v-if="label"
           :class="{ 'label-small': smallLabel }"
           @click="focus">{{ label }}</label>

    <multiselect
      ref="multiselect"
      :label="itemLabel"
      v-bind="$props"
      @input="onInput"
      @select="onSelect"
      @tag="onTag"
      @open="onOpen"
      @close="onClose"
    >
      <template slot="noResult">No Results</template>

      <template slot="noOptions">Empty List</template>

      <template slot="caret">
        <div class="actions">
          <div ref="iconCancel"
               class="icon"
               v-if="clearable"
               @click="onCancelClick">
            <icon-cross width="20"
                        height="20"
                        icon-color="#949494" />
          </div>
          <div class="icon icon-toggle"
               :class="toggleIconClasses"
               @mousedown.prevent.stop="toggle()">
            <icon-dropdown width="18"
                       height="18"
                       icon-color="#949494" />
          </div>
        </div>
      </template>
    </multiselect>

    <div class="error"
         v-show="showErrors && errors.length > 0">
      {{ errors[0] }}
    </div>
    <div class="error"
         v-show="showErrors && customErrors.length > 0">
      {{ customErrors[0] }}
    </div>

    <div class="description"
         v-if="description">
      {{ description }}
    </div>
  </div>
  </validation-provider>
</template>

<script>
import tippy from 'tippy.js'

export default {
  props: {
    id: {
      required: false,
      type: String
    },

    vid: {
      required: false,
      type: String
    },

    name: {
      required: false,
      type: String
    },

    value: {
      required: true
    },

    options: {
      required: true,
      type: Array
    },

    preselectFirst: {
      required: false,
      default: false
    },

    label: {
      required: false,
      type: String
    },

    itemLabel: {
      required: false,
      type: String,
      default: 'title'
    },

    trackBy: {
      required: false,
      type: String,
      default: 'id'
    },

    allowEmpty: {
      required: false,
      type: Boolean,
      default: true
    },

    searchable: {
      required: false,
      type: Boolean,
      default: true
    },

    deselectLabel: {
      required: false,
      type: String
    },

    showPointer: {
      required: false,
      type: Boolean,
      default: true
    },

    description: {
      required: false,
      type: String
    },

    placeholder: {
      required: false,
      type: String,
      default: 'Choose...'
    },

    showLabels: {
      required: false,
      type: Boolean,
      default: false
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    multiple: {
      required: false,
      type: Boolean,
      default: false
    },

    taggable: {
      required: false,
      type: Boolean,
      default: false
    },

    clearable: {
      required: false,
      type: Boolean,
      default: false
    },

    rules: {
      required: false,
      type: [String, null],
      default: ''
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    },

    topPosition: {
      required: false,
      type: Boolean,
      default: true
    },

    smallLabel: {
      required: false,
      type: Boolean,
      default: true
    },

    customErrors: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      isOpen: false
    }
  },

  mounted () {
    if (this.clearable) {
      tippy(this.$refs.iconCancel, {
        content: 'Clear',
        arrow: true
      })
    }
  },

  methods: {
    onInput (item) {
      this.$emit('input', item)
    },

    onSelect (item) {
      this.$emit('select', item)
    },

    onTag (tag) {
      this.$emit('tag', tag)
    },

    onOpen () {
      this.isOpen = true
    },

    onClose () {
      this.isOpen = false
    },

    onCancelClick () {
      this.$emit('input', null)
    },

    focus () {
      this.$refs.input.focus()
    },

    toggle () {
      if (this.isOpen) {
        this.$refs.multiselect.deactivate()
      } else {
        this.$refs.multiselect.activate()
      }
    },

    getClasses (validationClasses) {
      return {
        ...validationClasses,
        'has-validation-rules': !!this.rules,
        'disabled': this.disabled,
        'is-open': this.isOpen,
        'clearable': this.clearable,
        'dropdown-top': this.topPosition
      }
    }
  },

  computed: {
    toggleIconClasses () {
      return {
        'is-open': this.isOpen
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-dropdown {
    margin-bottom: 20px;

    .label {
      display: block;
      margin-bottom: 6px;
      color: map-get($colors, gray-1);
      font-size: 14px;
      font-weight: 600;
      &-small {
        display: block;
        margin-bottom: 10px;
        opacity: .8;
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-feature-settings: "ss04" off;
      }
    }

    .description {
      margin-top: 4px;
      font-size: 12px;
      line-height: 18px;
      color: map-get($colors, gray-1);
    }

    .error {
      margin-top: 4px;
      color: map-get($colors, red-2);
    }

    &.has-validation-rules {
      &.invalid /deep/ .multiselect__tags {
        border-color: map-get($colors, red-2)
      }

      &.valid /deep/ .multiselect__tags {
        border-color: map-get($colors, success)
      }
    }

    &.clearable /deep/ .multiselect__tags {
      padding-right: 56px;
    }

    .actions {
      display: flex;
      align-items: center;
      position: absolute;
      height: 30px;
      right: 6px;
      top: 6px;
      cursor: pointer;

      .icon {
        @include flex-center;

        width: 34px;
        height: 30px;
        transition: transform 0.2s ease;
      }

      .icon-toggle.is-open {
        transform: scaleY(-1);
      }
    }

    /deep/ {
      .multiselect {
        //font-weight: 600;
      }

      .multiselect__tags {
        border: 1px solid #e3e3e3;
        padding: 9px 30px 0 16px;
        border-radius: 6px;
        transition: border 0.2s;
        border: 1px solid #e9ebec;
        box-shadow: 0 2px 4px rgba(25,34,29,.05);
        height: 44px;
      }

      // .multiselect__current {
      //   border: 1px solid map-get($colors, gray-3);
      // }

      // .multiselect__content-wrapper {
      //   border: 1px solid map-get($colors, gray-3);
      // }

      // .multiselect--above .multiselect__content-wrapper {
      //   border: 1px solid map-get($colors, gray-3);
      // }

      .multiselect__placeholder,
      .multiselect__input::placeholder {
        //color: map-get($colors, gray-2);
        font-size: 14px;
        font-weight: 400;
        color: #a4a3a7;
        padding-top: 1px;
      }

      .multiselect__input,
      .multiselect__single {
        padding-left: 0;
        line-height: 24px;
      }

      .multiselect, .multiselect__input, .multiselect__single {
        font-size: 14px;
        color: map-get($colors, black);;
        font-weight: 500;
      }

      .multiselect--active .multiselect__tags {
        border: 1px solid map-get($colors, purple-1);
      }

      .multiselect__option--highlight {
        background: map-get($colors, purple-1);
        color: #fff;
      }

      .multiselect__option--selected.multiselect__option--highlight {
        background: map-get($colors, purple-1);
        color: #fff;
        font-weight: 500;
      }

      .multiselect__option--selected.multiselect__option--highlight:after {
        background: none;
        //color: map-get($colors, gray-1);
      }

      .multiselect__option--selected {
        background: none;
        font-weight: 500;
      }
    }
  }
</style>
