<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 100 100"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m50 24c-1.6562 0-3 1.3438-3 3v20h-20c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h20v20c0 1.6562 1.3438 3 3 3s3-1.3438 3-3v-20h20c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3h-20v-20c0-1.6562-1.3438-3-3-3z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
