<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <path d="M6 14L3.9087 17.4855C3.50878 18.152 3.9889 19 4.76619 19H20.2338C21.0111 19 21.4912 18.152 21.0913 17.4855L19 14" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M12.5333 23C13.1405 23 13.6333 22.552 13.6333 22H11.4333C11.4333 22.552 11.9261 23 12.5333 23Z" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M12 15H18" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5333 1V2.9998" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 14L6.59241 8.66834C6.84203 6.42173 8.34435 4.51115 10.4687 3.73866V3.73866C11.7809 3.2615 13.2191 3.2615 14.5313 3.73866V3.73866C16.6556 4.51115 18.158 6.42173 18.4076 8.66834L19 14" stroke="#BDBDBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'
export default {
  mixins: [iconMixin]
}
</script>
