import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import authenticationModule from './modules/authentication.module.js'
import nodesModule from './modules/nodes.module.js'
import teammatesModule from './modules/teammates.module'
import systemModule from './modules/system.module'
import userPreferencesModule from './modules/userPreferences.module'
import apiModule from './modules/api.module'
import seriesModule from './modules/series.module'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // modules: ['authentication', 'userPreferences']
  reducer: state => ({
    userPreferences: state.userPreferences,
    authentication: {
      authenticated: state.authentication.authenticated,
      token: state.authentication.token,
      google_and_traditional_account: state.authentication.google_and_traditional_account
    }
  })
})

export default new Vuex.Store({
  modules: {
    authentication: authenticationModule,
    nodes: nodesModule,
    teammates: teammatesModule,
    system: systemModule,
    userPreferences: userPreferencesModule,
    api: apiModule,
    snapshots: seriesModule
  },

  plugins: [
    vuexLocal.plugin
  ]
})
