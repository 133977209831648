<template>
  <button class="BaseButton"
          ref="button"
          :type="type"
          :class="classes"
          :style="styles"
          :disabled="disabled"
          @click="onClick($event)">
    <div class="content truncate"
         v-show="!loading">
      <component :is="iconComponent"
                 class="icon mr-2"
                 :width="iconWidth"
                 :height="iconHeight"
                 v-if="icon" />
      <slot></slot>
    </div>
    <base-loader-dots color="#fff"
                      v-show="loading" />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      required: false,
      type: String,
      default: 'button'
    },

    color: {
      required: false,
      type: String,
      default: 'primary'
    },

    icon: {
      required: false,
      type: String
    },

    iconWidth: {
      required: false,
      type: Number,
      default: 18
    },

    iconHeight: {
      required: false,
      type: Number,
      default: 18
    },

    size: {
      required: false,
      type: String,
      default: 'normal'
    },

    loading: {
      required: false,
      type: Boolean
    },

    disabled: {
      required: false,
      type: Boolean
    },

    active: {
      required: false,
      type: Boolean
    },

    outlined: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick (event) {
      // todo: remove disabled?
      if (this.loading || this.disabled) {
        return false
      }

      this.$emit('click', event)
    }
  },

  computed: {
    classes () {
      return [
        { 'is-loading': this.loading },
        { 'is-active': this.active },
        { 'is-outlined': this.outlined },
        { 'with-icon': !!this.icon },
        { [this.color]: true },
        { [this.size]: true }
      ]
    },

    iconComponent () {
      return require('@/components/Icons/' + this.icon).default
    },

    styles () {
      if (!this.loading) {
        return
      }

      if (this.$refs.button) {
        return {
          width: this.$refs.button.offsetWidth + 'px'
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
  .BaseButton {
    @include inline-flex-center;

    height: 44px;
    padding: 0 16px;

    font-size: 15px;
    font-weight: 500;
    // line-height: 16px;
    cursor: pointer;

    border-radius: 6px;
    border: 1px solid transparent;
    transition: 0.1s all;

    .content {
      display: flex;
      align-items: center;
    }

    &.small {
      height: 26px;
      padding: 0 9px;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      min-width: 26px;
    }

    &.primary {
      background-color: map-get($colors, purple-1);
      color: map-get($colors, white);

      .icon g {
        fill: map-get($colors, white);
      }

      &:not(:disabled):not(.is-outlined) {
        &:hover {
          background-color: darken(map-get($colors, purple-1), 5%);
        }

        &:active {
          background-color: darken(map-get($colors, purple-1), 10%);
        }
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid map-get($colors, purple-1);
        color: map-get($colors, purple-1);

        .icon g {
          fill: map-get($colors, purple-1);
        }

        .base-loader-dots .dots > * {
          background-color: map-get($colors, purple-1) !important;
        }

        &:not(:disabled):hover {
          background-color: map-get($colors, purple-1);
          color: map-get($colors, white);

          .icon g {
            fill: map-get($colors, white);
          }

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }

        &:not(:disabled):active {
          background-color: darken(map-get($colors, purple-1), 10%);

          .icon g {
            fill: darken(map-get($colors, purple-1), 10%);
          }

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }
      }
    }

    &.light {
      background-color: map-get($colors, gray-4);
      color: map-get($colors, gray-1);

      .icon g {
        fill: map-get($colors, gray-1);
      }

      &:not(:disabled):not(.is-outlined) {
        &:hover {
          background-color: darken(map-get($colors, gray-4), 5%);
        }

        &:active {
          background-color: darken(map-get($colors, gray-4), 10%);
        }
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid map-get($colors, gray-3);
        color: map-get($colors, gray-1);

        .icon g {
          fill: map-get($colors, gray-1);
        }

        .base-loader-dots .dots > * {
          background-color: map-get($colors, gray-1) !important;
        }

        &:not(:disabled):hover {
          background-color: map-get($colors, gray-1);
          color: map-get($colors, white);
          border-color: map-get($colors, gray-1);

          .icon g {
            fill: map-get($colors, white);
          }

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }

        &:not(:disabled):active {
          background-color: darken(map-get($colors, gray-1), 10%);

          .icon g {
            fill: darken(map-get($colors, gray-1), 10%);
          }

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }
      }
    }

    &.success {
      background-color: map-get($colors, green-1);
      color: map-get($colors, white);

      .icon g {
        fill: map-get($colors, white);
      }

      &:not(:disabled):not(.is-outlined) {
        &:hover {
          background-color: darken(map-get($colors, green-1), 5%);
        }

        &:active {
          background-color: darken(map-get($colors, green-1), 10%);
        }
      }

      &.is-outlined {
        background-color: transparent;
        border: 1px solid map-get($colors, green-1);
        color: map-get($colors, green-1);

        .icon g {
          fill: map-get($colors, green-1);
        }

        .base-loader-dots .dots > * {
          background-color: map-get($colors, green-1) !important;
        }

        &:not(:disabled):hover {
          background-color: map-get($colors, green-1);
          color: map-get($colors, white);

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }

        &:not(:disabled):active {
          background-color: darken(map-get($colors, green-1), 10%);
          color: map-get($colors, white);

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }
      }
    }

    &.danger {
      background-color: map-get($colors, red-1);
      color: map-get($colors, white);

      .icon g {
        fill: map-get($colors, white);
      }

      &:not(:disabled):not(.is-outlined) {
        &:hover {
          background-color: darken(map-get($colors, red-1), 5%);
        }

        &:active {
          background-color: darken(map-get($colors, red-1), 10%);
        }
      }

      &.is-outlined {
        border: 1px solid map-get($colors, red-1);
        color: map-get($colors, red-1);

        .icon g {
          fill: map-get($colors, red-1);
        }

        .base-loader-dots .dots > * {
          background-color: #eb5656 !important;
        }

        &:not(:disabled):hover {
          background-color: map-get($colors, red-1);
          color: map-get($colors, white);

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }

        &:not(:disabled):active {
          background-color: darken(map-get($colors, red-1), 10%);
          color: map-get($colors, white);

          .base-loader-dots .dots > * {
            background-color: #fff !important;
          }
        }
      }
    }

    &.is-outlined {
      background-color: transparent;
    }

    &:disabled {
      cursor: default;
    }

    &:disabled:not(.is-outlined) {
      background-color: map-get($colors, gray-3);
      color: map-get($colors, white);
    }

    &:disabled.is-outlined {
      border: 1px solid map-get($colors, gray-3);
      color: map-get($colors, gray-3);
    }
  }
</style>
