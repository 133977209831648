<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path d="M1 3.4L3.61589 6.53907C3.81579 6.77895 4.18421 6.77894 4.38411 6.53907L9 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
