<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 20 15"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="M3.00002 0.399902H16.7143C18.1503 0.399902 19.3143 1.56396 19.3143 2.9999V11.9999C19.3143 13.4358 18.1502 14.5999 16.7143 14.5999H3.00002C1.56408 14.5999 0.400024 13.4358 0.400024 11.9999V2.9999C0.400024 1.56396 1.56408 0.399902 3.00002 0.399902ZM3.00002 1.9999C2.44774 1.9999 2.00002 2.44762 2.00002 2.9999V11.9999C2.00002 12.5522 2.44774 12.9999 3.00002 12.9999H16.7143C17.2666 12.9999 17.7143 12.5522 17.7143 11.9999V2.9999C17.7143 2.44762 17.2666 1.9999 16.7143 1.9999H3.00002Z" />
      <path d="M3.55725 10.6428C3.55725 10.2009 3.91542 9.84277 4.35725 9.84277L15.3573 9.84277C15.7991 9.84277 16.1573 10.2009 16.1573 10.6428C16.1573 11.0846 15.7991 11.4428 15.3573 11.4428L4.35725 11.4428C3.91542 11.4428 3.55725 11.0846 3.55725 10.6428Z" />
      <path d="M3.54279 4.35684C3.54279 3.47318 4.25913 2.75684 5.14279 2.75684H7.49993C8.38358 2.75684 9.09993 3.47318 9.09993 4.35684L9.09993 6.71398C9.09993 7.59763 8.38358 8.31398 7.49993 8.31398L5.14279 8.31398C4.25913 8.31398 3.54279 7.59763 3.54279 6.71398V4.35684ZM5.14279 4.35684V6.71398H7.49993V4.35684H5.14279Z" />
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
