<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <g fill-rule="evenodd">
        <path d="m36.328 14.258c0 4.8555-3.9336 8.7891-8.7891 8.7891-4.8555 0-8.7891-3.9336-8.7891-8.7891 0-4.8555 3.9336-8.7891 8.7891-8.7891 4.8555 0 8.7891 3.9336 8.7891 8.7891"/>
        <path d="m81.055 14.258c0 4.8555-3.9336 8.7891-8.7891 8.7891s-8.7891-3.9336-8.7891-8.7891c0-4.8555 3.9336-8.7891 8.7891-8.7891s8.7891 3.9336 8.7891 8.7891"/>
        <path d="m36.328 50c0 4.8555-3.9336 8.7891-8.7891 8.7891-4.8555 0-8.7891-3.9336-8.7891-8.7891s3.9336-8.7891 8.7891-8.7891c4.8555 0 8.7891 3.9336 8.7891 8.7891"/>
        <path d="m81.055 50c0 4.8555-3.9336 8.7891-8.7891 8.7891s-8.7891-3.9336-8.7891-8.7891 3.9336-8.7891 8.7891-8.7891 8.7891 3.9336 8.7891 8.7891"/>
        <path d="m36.328 85.742c0 4.8555-3.9336 8.7891-8.7891 8.7891-4.8555 0-8.7891-3.9336-8.7891-8.7891 0-4.8555 3.9336-8.7891 8.7891-8.7891 4.8555 0 8.7891 3.9336 8.7891 8.7891"/>
        <path d="m81.055 85.742c0 4.8555-3.9336 8.7891-8.7891 8.7891s-8.7891-3.9336-8.7891-8.7891c0-4.8555 3.9336-8.7891 8.7891-8.7891s8.7891 3.9336 8.7891 8.7891"/>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
