import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
  localize
} from 'vee-validate/dist/vee-validate.full'
import validator from 'validator'
import { isValidNumber } from 'libphonenumber-js'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

localize('en_US', require('@/lang/validation/en_US.js').default)

extend('url', {
  validate (value) {
    return validator.isURL(value)
  },

  message: 'This is not a valid url'
})

extend('urls', {
  validate (value) {
    const urls = value.replace(/\s*,\s*/g, ',')
    const urlsList = urls.split(',')
    const notUrls = []
    for (const url in urlsList) {
      if (!(validator.isURL(urlsList[url]))) {
        notUrls.push(url)
      }
    }
    return !notUrls.length
  },

  message: 'Invalid data'
})

extend('urlWithProtocol', {
  validate (value) {
    return validator.isURL(value, {
      require_protocol: true
    })
  },

  message: 'This is not a valid url'
})

extend('json', {
  validate (value) {
    return validator.isJSON(value)
  },

  message: 'This is not a valid json'
})

extend('uniqueEmail', {
  async validate (email) {
    const response = await axios.get('/auth/check-email', { params: { email } })

    return response.data.valid
  },

  message: 'This email is already taken or it is not valid'
})

extend('statusPageDomainValid', {
  async validate (domain) {
    try {
      const response = await axios.get('/status-pages/check-domain', { params: { domain } })
      return response.data.valid
    } catch (error) {
      this.message = error.response?.data?.message ?? 'Invalid domain name'
    }
  }
})

extend('uniqueMonitorUrl', {
  async validate (url) {
    const response = await axios.get('/monitors/check-url', { params: { url } })

    return !response.data.taken
  },

  message: 'You have already added this url'
})

extend('mobilePhone', {
  validate (value) {
    return isValidNumber(value)
  },

  message: 'This is not a valid mobile phone number'
})

/**
 * Similar to the built-in `eager` mode
 * will first check if input is dirty before flagging it as invalid on initial touch
 *
 * @see https://github.com/logaretm/vee-validate/issues/2430
 */
export function eagerDirtyValidation (context) {
  const { errors, flags } = context

  if (errors.length) {
    return {
      on: ['input', 'change']
    }
  }

  if (flags.dirty) {
    return {
      on: ['change', 'blur']
    }
  }

  return {
    on: []
  }
}

setInteractionMode('eagerDirty', eagerDirtyValidation)
