<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m54.34 76.273 43.863-43.711c2.3945-2.543 2.3945-6.4375 0-8.832s-6.4375-2.3945-8.832 0l-39.371 39.371-39.371-39.371c-2.543-2.3945-6.4375-2.3945-8.832 0s-2.3945 6.2891 0 8.832l43.711 43.711c2.543 2.3945 6.4375 2.3945 8.832 0z" fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
