<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white" fill="#1c1824" stroke-opacity="0.1"/>
    <path d="M13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L13.7071 8.70711ZM12.2929 16.7071C12.6834 17.0976 13.3166 17.0976 13.7071 16.7071C14.0976 16.3166 14.0976 15.6834 13.7071 15.2929L12.2929 16.7071ZM12.2929 7.29289L9 10.5858L10.4142 12L13.7071 8.70711L12.2929 7.29289ZM9 13.4142L12.2929 16.7071L13.7071 15.2929L10.4142 12L9 13.4142ZM9 10.5858C8.21895 11.3668 8.21895 12.6332 9 13.4142L10.4142 12L10.4142 12L9 10.5858Z" fill="white"/>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
