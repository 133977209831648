<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 14 13"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="M13.0036 0H1.19574H1.14593C0.398588 0 0 0.254623 0 0.814778V3.56467C0 3.81929 0.19928 4.02298 0.448422 4.07392V10.6431C0.448422 11.2542 1.04629 12.069 1.69399 12.069H12.5552C13.2527 12.069 13.5516 11.2542 13.5516 10.6431V4.07389C13.8007 4.02295 14 3.81926 14 3.56464V0.814778C13.9502 0 13.2527 0 13.0036 0ZM12.5552 10.5921C12.5053 10.7958 12.4555 10.9486 12.4057 10.9995H1.69394C1.5943 10.9486 1.39502 10.694 1.39502 10.5921V4.07389H3.43771V5.04142C3.43771 5.70344 4.08541 6.31451 4.73308 6.31451H9.4662C10.0641 6.31451 10.5125 5.80526 10.5125 5.04139V4.07389H12.5552V10.5921ZM4.43416 4.07389H9.46617V5.04142C9.46617 5.1942 9.41634 5.29604 9.41634 5.29604H4.73311C4.63347 5.29604 4.43419 5.14326 4.43419 5.04142L4.43416 4.07389ZM12.9537 3.10633H10.0142H3.93594H0.996429V1.01847C1.04624 1.01847 1.09607 1.01847 1.1459 1.01847H12.9537L12.9537 3.10633Z" fill="#888799"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
