<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="18"
       height="18"
       viewBox="0 0 18 18"
       @click="$emit('click')">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 2.00374L10.2423 2.00374C9.71199 2.00374 9.20341 2.21434 8.82836 2.58923L2.47547 8.93948C1.88947 9.52524 1.88937 10.4752 2.47524 11.061L6.93934 15.5251C7.52513 16.1109 8.47487 16.1109 9.06066 15.5251L15.4142 9.17157C15.7893 8.7965 16 8.28779 16 7.75736L16 4.00374C16 2.89917 15.1046 2.00374 14 2.00374ZM10.2423 0.0037424L14 0.0037384C16.2091 0.00373608 18 1.7946 18 4.00374L18 7.75736C18 8.81822 17.5786 9.83564 16.8284 10.5858L10.4749 16.9393C9.10804 18.3062 6.89196 18.3062 5.52513 16.9393L1.06103 12.4752C-0.306008 11.1082 -0.305773 8.89173 1.06155 7.52498L7.41444 1.17473C8.16454 0.424941 9.1817 0.00374353 10.2423 0.0037424Z" fill="#CCCCCC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289Z" fill="#CCCCCC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 7.29289C13.0976 7.68342 13.0976 8.31658 12.7071 8.70711L8.70711 12.7071C8.31658 13.0976 7.68342 13.0976 7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289Z" fill="#CCCCCC"/>
        <path d="M13 4C13 3.44772 13.4477 3 14 3V3C14.5523 3 15 3.44772 15 4V4C15 4.55228 14.5523 5 14 5V5C13.4477 5 13 4.55228 13 4V4Z" fill="#CCCCCC"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
