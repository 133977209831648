<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')"
       @mouseover="isHover = true"
       @mouseout="isHover = false">
    <g class="icon"
       :fill="fillColor">
      <path d="m25.625 50c0 6.7891-5.5039 12.293-12.293 12.293-6.7891 0-12.289-5.5039-12.289-12.293s5.5-12.293 12.289-12.293c6.7891 0 12.293 5.5039 12.293 12.293"/>
      <path d="m62.293 50c0 6.7891-5.5039 12.293-12.293 12.293s-12.293-5.5039-12.293-12.293 5.5039-12.293 12.293-12.293 12.293 5.5039 12.293 12.293"/>
      <path d="m98.957 50c0 6.7891-5.5 12.293-12.289 12.293-6.7891 0-12.293-5.5039-12.293-12.293s5.5039-12.293 12.293-12.293c6.7891 0 12.289 5.5039 12.289 12.293"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>

<style lang="scss" scoped>
  .icon {
    transition: fill .4s ease;
  }
</style>
