<template>
  <div class="BaseToggle toggle--size-small"
       :class="classes">
    <input ref="input"
           :disabled="disabled"
           type="checkbox"
           v-model="isChecked"
           @change="onChange">
    <label @click="onLabelClick">
      <div class="toggle__switch"
           :data-checked="checkedText"
           :data-unchecked="uncheckedText"></div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      required: false,
      type: Boolean,
      default: false
    },

    checkedText: {
      required: false,
      type: String,
      default: 'On'
    },

    uncheckedText: {
      required: false,
      type: String,
      default: 'Off'
    }
  },

  data () {
    return {
      isChecked: this.value
    }
  },

  methods: {
    onLabelClick () {
      this.$refs.input.click()
    },

    onChange () {
      this.$emit('input', this.isChecked)
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.isChecked = !!value
      },
      immediate: true
    }
  },

  computed: {
    classes () {
      return {
        checked: this.checked
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin toggle-branding(
    $toggle-off-color: #777,
    $toggle-on-color: map-get($colors, success),
    $toggle-inactive-text: rgba(white, 0.5),
    $toggle-transition: cubic-bezier(0,1,0.5,1)
  ) {
    input[type="checkbox"] {
      &[disabled] ~ label {
        color: rgba($toggle-off-color, 0.5);
      }
      &:focus ~ label, &:hover ~ label {
        .toggle__switch {
          background-color: $toggle-off-color;
          &:after { color: darken($toggle-off-color, 10%); }
        }
      }
      &:hover ~label { color: darken($toggle-off-color, 5%); }
      &:checked {
        ~ label {
          &:hover { color: darken($toggle-on-color, 3%); }

          .toggle__switch {
            background-color: $toggle-on-color;
            &:after { color: darken($toggle-on-color, 5%); }
          }
        }

        &:focus, &:hover {
          ~ label {
            .toggle__switch {
              background-color: $toggle-on-color;
              &:after { color: darken($toggle-on-color, 10%); }
            }
          }
        }
      }
    }

    label {
      .toggle__switch {
        transition: background-color 0.3s $toggle-transition;
        background: lighten($toggle-off-color, 5%);
        font-weight: 600;
        &:before { color: $toggle-inactive-text; }
        &:after {
          // transition: 0.3s $toggle-transition;
          transition: transform .25s,width .25s;
            transition-timing-function: cubic-bezier(.23,2.01,.43,.66);
          color: $toggle-off-color;
        }
      }
    }
  }

  @mixin toggle-appearance
  (
    $toggle-width: 134px,
    $toggle-height: 36px,
    $toggle-border-radius: 100px,
    $toggle-offset: 2px,
    $toggle-label-font-size: 14px,
    $toggle-switch-font-size: 12px,
    $toggle-shadow: 0 3px 3px rgba(black, 0.1)
  ){
    $toggle-switch-width: $toggle-width/2;
    width: $toggle-width;

    input[type="checkbox"] {
      &:focus ~ label, &:hover ~ label {
        .toggle__switch {
          &:after { box-shadow: $toggle-shadow; }
        }
      }

      &:checked {
        ~ label {
          .toggle__switch {
            &:after { transform: translate3d($toggle-width - ($toggle-switch-width + $toggle-offset),0,0); }
          }
        }
        &:focus, &:hover {
          ~ label {
            .toggle__switch { &:after { box-shadow: $toggle-shadow; } }
          }
        }
      }
    }

    label {
      font-size: $toggle-label-font-size;

      .toggle__switch {
        height: $toggle-height;
        flex: 0 0 $toggle-width;
        border-radius: $toggle-border-radius;

        &:before {
          left: $toggle-width/2;
          font-size: $toggle-switch-font-size;
          line-height: $toggle-height;
          width: $toggle-width/2;
          padding: 0 12px;
        }

        &:after {
          top: $toggle-offset; left: $toggle-offset;
          border-radius: $toggle-border-radius/2;
          width: $toggle-switch-width - $toggle-offset;
          line-height: $toggle-height - ($toggle-offset*2);
          font-size: $toggle-switch-font-size;
        }

        &:hover {
          &:after { box-shadow: $toggle-shadow; }
        }
      }
    }
  }

  .BaseToggle {
    position: relative;

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;

      &[disabled] ~ label {
        pointer-events: none;
        .toggle__switch {
          opacity: 0.4;
        }
      }

      &:checked {
        ~ label {
          .toggle__switch {
            &:before {
              content: attr(data-unchecked);
              left: 0;
            }

            &:after {
              content: attr(data-checked);
            }
          }
        }
      }
    }

    &.even-smaller label .toggle__switch {
      height: 24px !important;
    }

    &.even-smaller label .toggle__switch:after {
      line-height: 20px !important;
    }

    label {
      user-select: none;
      position: relative;
      display: flex;
      align-items: center;

      .toggle__switch {
        position: relative;
        cursor: pointer;

        &:before {
          content: attr(data-checked);
          position: absolute;
          top: 0;
          text-transform: uppercase;
          text-align: center;
        }

        &:after {
          content: attr(data-unchecked);
          position: absolute;
          z-index: 5;
          text-transform: uppercase;
          text-align: center;
          background: white;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    @include toggle-branding;
    @include toggle-appearance;

    &.toggle--size-small {
      @include toggle-appearance (
        90px, // Toggle width
        28px,  // Toggle height
        100px,   // Toggle border radius
        2px,   // Offset (distance btw switch and box)
        13px,  // Label font size
        10px,  // Switch font size
        0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
      );
    }

    &.toggle--size-small.even-smaller {
      @include toggle-appearance (
        44px, // Toggle width
        24px,  // Toggle height
        100px,   // Toggle border radius
        2px,   // Offset (distance btw switch and box)
        0,  // Label font size
        0px,  // Switch font size
        0 0 0 rgba(black, 0) // Switch shadow on hover/focus
      );
    }

    &.toggle--size-large {
      @include toggle-appearance (
        160px, // Toggle width
        50px,  // Toggle height
        100px,   // Toggle border radius
        2px,   // Offset (distance btw switch and box)
        14px,  // Label font size
        14px,  // Switch font size
        0 4px 4px rgba(black, 0.4) // Switch shadow on hover/focus
      );
    }
  }
</style>
