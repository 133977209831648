<template>
  <button class="BaseButtonIcon"
          ref="button"
          :type="type"
          :class="classes"
          :disabled="disabled"
          @click="onClick($event)">
    <component :is="iconComponent"
               class="icon"
               width="16"
               height="16" />
  </button>
</template>

<script>
import tippy from 'tippy.js'

export default {
  props: {
    type: {
      required: false,
      type: String,
      default: 'button'
    },

    icon: {
      required: false,
      type: String
    },

    color: {
      required: false,
      type: String,
      default: 'light'
    },

    size: {
      required: false,
      type: String,
      default: 'normal'
    },

    disabled: {
      required: false,
      type: Boolean
    },

    hint: {
      required: false,
      type: String
    }
  },

  methods: {
    onClick (event) {
      this.$emit('click', event)
    }
  },

  mounted () {
    if (this.hint) {
      tippy(this.$refs.button, {
        content: this.hint
      })
    }
  },

  computed: {
    classes () {
      return [
        { [this.color]: true },
        { [this.size]: true }
      ]
    },

    iconComponent () {
      return require('@/components/Icons/' + this.icon).default
    }
  }
}
</script>

<style lang="scss">
  .BaseButtonIcon {
    @include inline-flex-center;

    flex-shrink: 0;

    width: 40px;
    height: 40px;

    padding: 0;
    margin: 0;

    border-radius: 6px;
    border: 0;

    cursor: pointer;

    transition: 0.2s all;

    &.light {
      background-color: map-get($colors, white);

      .icon g {
        fill: #8fa4af;
      }
    }

    &:hover {
      background-color: map-get($colors, purple-1);

      svg path {
        fill: #fff !important;
      }
    }

    &.small {
      height: 24px;
      padding: 6px 16px;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
    }

    &:disabled {
      background-color: map-get($colors, gray-3);
      color: map-get($colors, white);
    }
  }
</style>
