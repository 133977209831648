<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path d="M0 0H16V1H0V0Z" fill="#1B1823"/>
    <path d="M0 4H16V6H0V4Z" fill="#1B1823"/>
    <path d="M0 9H16V12H0V9Z" fill="#1B1823"/>
  </svg>

</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
