<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g opacity="0.2">
      <path d="M1 8C1 4.13413 4.14726 1 8.02933 1C9.97036 1 11.7277 1.78353 13 3.05007" stroke="black" fill="none" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 4H13.7485C13.8875 4 14 3.8875 14 3.7485V1" stroke="black" fill="none" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 8C15 11.8659 11.8527 15 7.97067 15C6.02964 15 4.27231 14.2165 3 12.9499" stroke="black" fill="none" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 12H2.2515C2.1125 12 2 12.1125 2 12.2515V15" stroke="black" fill="none" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
