<template>
  <validation-provider ref="provider"
                       :rules="rules"
                       :vid="vid"
                       :name="name"
                       mode="lazy"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="BaseTextArea"
         :class="getClasses(classes)">
      <label class="label"
             v-if="label"
             @click="focus">{{ label }}</label>

      <div class="textarea-wrapper">
        <textarea
          :id="id"
          ref="textarea"
          class="textarea"
          :name="name"
          :value="value"
          :disabled="disabled"
          :placeholder="placeholder"
          :rows="rows"
          @input="onInput"
          @change="onChange"
          @blur="onBlur"
          @keydown.enter="onSubmit"
        />
      </div>

      <div class="error"
           v-show="showErrors && errors.length > 0">
        {{ errors[0] }}
      </div>
      <div class="error"
           v-show="showErrors && customErrors.length > 0">
        {{ customErrors[0] }}
      </div>

      <div class="description"
           v-if="description">
        {{ description }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
// TODO: add focus and blur events @see https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314
export default {
  props: {
    id: {
      required: false,
      type: String
    },

    vid: {
      required: false,
      type: String
    },

    value: {
      required: true
    },

    name: {
      required: false,
      type: String,
      default: 'name'
    },

    label: {
      required: false,
      type: String
    },

    description: {
      required: false,
      type: String
    },

    rules: {
      required: false,
      type: [String, null],
      default: ''
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    },

    placeholder: {
      required: false,
      type: String,
      default: null
    },

    autocomplete: {
      required: false,
      type: String,
      default: 'off'
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    rows: {
      required: false,
      type: String
    },

    isSubmitOnEnter: {
      required: false,
      type: Boolean,
      default: false
    },

    customErrors: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  methods: {
    getClasses (validationClasses) {
      let displayValidation = false

      if (this.$refs.provider) {
        const required = this.$refs.provider.flags.required

        if (required) {
          displayValidation = true
        } else {
          // Okay to use == instead of ===
          // @see https://stackoverflow.com/questions/2647867/how-can-i-determine-if-a-variable-is-undefined-or-null
          if (this.value === '' || this.value == null || !this.rules) {
            displayValidation = false
          } else {
            displayValidation = true
          }
        }
      }

      return {
        ...validationClasses,
        'display-validation': displayValidation,
        'has-password-toggle': !!this.showPasswordToggle,
        'disabled': this.disabled
      }
    },

    onInput (event) {
      this.$emit('input', event.target.value)
    },

    onChange (event) {
      this.$emit('change', event.target.value)
    },

    onSubmit (event) {
      if (this.isSubmitOnEnter) {
        event.preventDefault()
        this.$emit('submit', event.target.value)
      }
    },

    onBlur () {
      this.$emit('blur', this.value)
    },

    focus () {
      this.$refs.textarea.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseTextArea {
    position: relative;
    margin-bottom: 20px;

    .textarea {
      width: 100%;
      padding: 13px 16px;

      background: #fff;

      border: 1px solid map-get($colors, border);
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      background: #FFFFFF;
      border-radius: 6px;

      outline: none;

      font-size: 14px;
      font-weight: 500;
      transition: all 0.2s;

      &:hover,
      &:focus {
        box-shadow: 0px 3px 5px rgba(25, 34, 29, 0.15);
        border-color: map-get($colors, purple-1);
      }

      &::placeholder {
        color: #a4a3a7;
        font-weight: 400;
      }
    }

    .label {
      // display: block;
      // margin-bottom: 6px;
      // color: map-get($colors, gray-1);
      // font-size: 14px;
      // font-weight: 600;
      display: block;
      margin-bottom: 10px;
      opacity: .8;

      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }

    .textarea-wrapper {
      position: relative;
    }

    .description {
      margin-top: 4px;
      font-size: 14px;
      line-height: 21px;
      color: map-get($colors, gray-1);
    }

    &.display-validation {
      &.invalid .textarea {
        border-color: map-get($colors, red-2);
      }

      &.valid .textarea {
        border-color: map-get($colors, success);
      }
    }

    &.disabled .textarea {
      background-color: #eee;
    }

    .error {
      margin-top: 4px;
      color: map-get($colors, red-2);
    }
  }
</style>
