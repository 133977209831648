import nodesApi from '@/api/nodesApi.js'

export default {
  namespaced: true,

  state: {
    nodes: []
  },

  actions: {
    async load ({ commit }) {
      const nodes = await nodesApi.get()

      commit('setNodes', nodes)
    }
  },

  mutations: {
    setNodes (state, nodes) {
      state.nodes = nodes
    }
  },

  getters: {
    getNodeById: (state) => (id) => {
      return state.nodes.find(node => node.id === id)
    }
  }
}
