export default {
  namespaced: true,

  state: {
    sidebar: 'desktop',
    monitorsPerPage: 20
  },

  mutations: {
    setSidebar (state, type) {
      state.sidebar = type
    },

    setMonitorsPerPage (state, action) {
      state.monitorsPerPage = action
    }
  }
}
