const baseURL = 'tags'

export default {
  /**
   * Get tag list.
   *
   * @param params
   * @returns Promise
   */
  async get (params = {}) {
    const response = await axios.get(`${baseURL}/`, {
      params
    })

    return response.data
  },

  /**
   * Delete tag.
   *
   * @param id
   * @returns Promise
   */
  async delete (id) {
    const response = await axios.delete(`${baseURL}/${id}`)

    return response.data
  }
}
