<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <g opacity="0.5">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.11111C0 1.84061 1.84061 0 4.11111 0H13.8889C16.1594 0 18 1.84061 18 4.11111V13.8889C18 16.1594 16.1594 18 13.8889 18H4.11111C1.84061 18 0 16.1594 0 13.8889V4.11111ZM4.11111 2C2.94518 2 2 2.94518 2 4.11111V13.8889C2 15.0548 2.94518 16 4.11111 16H13.8889C15.0548 16 16 15.0548 16 13.8889V4.11111C16 2.94518 15.0548 2 13.8889 2H4.11111ZM9 5C9 4.44772 9.44771 4 10 4H12.5C13.3284 4 14 4.67157 14 5.5V8C14 8.55228 13.5523 9 13 9C12.4477 9 12 8.55228 12 8V6H10C9.44771 6 9 5.55228 9 5ZM5 9C5.55228 9 6 9.44771 6 10V12H8C8.55228 12 9 12.4477 9 13C9 13.5523 8.55228 14 8 14H5.5C4.67157 14 4 13.3284 4 12.5V10C4 9.44771 4.44772 9 5 9Z" fill="#1B1823"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
