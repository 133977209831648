<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')"
       class="ml-1"
       transform="rotate(-180)">
    <g :fill="fillColor">
      <path d="m13.145 91.355v-82.766c0-3.4961 3.4961-6.332 6.332-6.332h15.074c3.4961 0 6.332 2.8359 6.332 6.332v82.766c0 3.4961-3.4961 6.332-6.332 6.332h-15.074c-3.4961 0-6.332-2.8359-6.332-6.332z"/>
      <path d="m59.016 91.355v-82.766c0-3.4961 3.4961-6.332 6.332-6.332h15.074c3.4961 0 6.332 2.8359 6.332 6.332v82.766c0 3.4961-3.4961 6.332-6.332 6.332h-15.074c-3.4961 0-6.332-2.8359-6.332-6.332z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
