<template>
  <div class="BaseTagsInput" :class="classes">
    <vue-tags-input v-model="tag"
                    :tags="tagsCopy"
                    :autocomplete-items="filteredItems"
                    :add-only-from-autocomplete="addOnlyFromAutocomplete"
                    :placeholder="placeholder"
                    @tags-changed="onTagsChanged" />
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  components: {
    VueTagsInput
  },

  model: {
    prop: 'tags',
    event: 'change'
  },

  props: {
    tags: {
      required: true,
      type: Array
    },

    autocompleteTags: {
      required: false,
      type: Array,
      default: () => ([])
    },

    addOnlyFromAutocomplete: {
      required: false,
      type: Boolean,
      default: false
    },

    keyName: {
      required: false,
      type: String,
      default: 'name'
    },

    bordered: {
      required: false,
      type: Boolean,
      default: true
    },

    placeholder: {
      required: false,
      type: String
    }
  },

  data () {
    return {
      tag: '',
      tagsCopy: []
    }
  },

  created () {
    this.tagsCopy = this.transformTags(this.tags.slice())
  },

  methods: {
    onTagsChanged (tags) {
      this.$emit('change', tags.map(tag => ({
        [this.keyName]: tag.text
      })))
    },

    transformTags (tags) {
      return tags.map(tag => ({
        text: tag[this.keyName]
      }))
    }
  },

  computed: {
    classes () {
      return {
        bordered: this.bordered
      }
    },

    filteredItems () {
      let tags = this.transformTags(this.autocompleteTags)

      return tags.filter(tag => {
        return tag.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseTagsInput {
    /deep/ {
      .vue-tags-input {
        max-width: none;

        .ti-input {
          // border: 1px solid transparent;
          // border-radius: 4px;
          
          height: 44px;

          background: #fff;

          border: 1px solid transparent;
          background: #FFFFFF;
          border-radius: 6px;

          outline: none;

          font-size: 14px;
          font-weight: 500;
          transition: all 0.2s;
          padding: 6px;

          &:hover,
          &:focus {
            box-shadow: 0px 3px 5px rgba(101, 81, 224, 0.15);
            border-color: map-get($colors, purple-1);
          }

          &::placeholder {
            color: #a4a3a7;
            font-weight: 400;
          }
        }

        .ti-tag {
          font-size: 14px;
          border-radius: 4px;
          background-color: map-get($colors, purple-1);
        }

        .ti-new-tag-input {
          font-size: 14px;
        }

        .ti-autocomplete {
          width: auto;
          min-width: 150px;
          border: 1px solid map-get($colors, gray-3);
          border-radius: 4px;
          box-shadow: 0 2px 8px rgba(0,0,0,.2);

          .ti-item  {
            padding: 10px;
            font-size: 16px;
            border-radius: 4px;
          }
        }
        .ti-icon-close {
          opacity: .5;
          transition: opacity 0.2s;
          
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.bordered /deep/ .vue-tags-input .ti-input {
      border: 1px solid map-get($colors, border);
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

      &:hover,
      &:focus {
        box-shadow: 0px 3px 5px rgba(101, 81, 224, 0.15);
        border-color: map-get($colors, purple-1);
      }
    }
  }
</style>
