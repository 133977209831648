<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
       <rect x="0.5" y="-0.5" width="23" height="23" rx="11.5" transform="matrix(-1 0 0 1 24 1)" stroke="white" stroke-opacity="0.1" fill="#1C1824"/>
       <path d="M10.2929 8.70711C9.90237 8.31658 9.90237 7.68342 10.2929 7.29289C10.6834 6.90237 11.3166 6.90237 11.7071 7.29289L10.2929 8.70711ZM11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L11.7071 16.7071ZM11.7071 7.29289L15 10.5858L13.5858 12L10.2929 8.70711L11.7071 7.29289ZM15 13.4142L11.7071 16.7071L10.2929 15.2929L13.5858 12L15 13.4142ZM15 10.5858C15.7811 11.3668 15.781 12.6332 15 13.4142L13.5858 12L13.5858 12L15 10.5858Z" fill="white"/>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
