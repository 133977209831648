<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <path d="M23 23V21.339C23 19.964 22.0643 18.7657 20.7311 18.4328L15.7657 17.1913C15.3169 17.0791 15.0037 16.6735 14.9172 16.059L14.926 15.2039C15.0265 14.4596 15.3968 13.6163 16.0018 12.9533C17.9693 10.7959 17.9693 9.07987 17.9693 7.12553C17.9686 3.75 15.3198 1.06087 12 1C8.6802 1.06087 6.0314 3.75 6.0314 7.1248C6.0314 9.07913 6.0314 10.7951 7.99893 12.9526C8.60393 13.6155 8.97427 14.4589 9.04393 15.2641L9.05273 16.1191C8.99627 16.6735 8.68313 17.0783 8.23433 17.1905L3.26893 18.4321C1.93573 18.7665 1 19.9647 1 21.339V23" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
