<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m50 0c27.688 0 50 22.312 50 50s-22.312 50-50 50-50-22.312-50-50 22.312-50 50-50zm4.1328 16.527v31.816l15.289 15.703-5.3711 5.3711-18.184-17.77v-35.125h8.2656z" fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
