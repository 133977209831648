import tagsApi from '@/api/tagsApi.js'

export default {
  namespaced: true,

  state: {
    // Later on we may do live search for tags, but
    // for now it's okay to load them in storage
    tags: [],

    warningUptimeThreshold: 0.99,
    dangerUptimeThreshold: 0.97,

    warningResponseTimeThreshold: 1.5,
    dangerResponseTimeThreshold: 3,

    incidentUpdateStatuses: [
      {
        value: 'exploring',
        label: 'Exploring'
      },
      {
        value: 'identified',
        label: 'Identified'
      },
      {
        value: 'update',
        label: 'Update'
      },
      {
        value: 'resolved',
        label: 'Resolved'
      }
    ]
  },

  actions: {
    async loadTags ({ commit }) {
      const tags = await tagsApi.get()

      commit('setTags', tags)
    },

    async deleteTag ({ commit, dispatch }, id) {
      await tagsApi.delete(id)

      dispatch('loadTags')
    }
  },

  mutations: {
    setTags (state, tags) {
      state.tags = tags
    }
  }
}
