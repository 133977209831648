<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 22 22"
       @click="$emit('click')">
    <g opacity="0.2">
      <rect x="6" y="3" width="13" height="13" rx="6.5" stroke="black" stroke-width="2" fill="none"/>
      <path d="M3 19L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
