<template>
  <div class="base-loader-dots"
       :class="classes">
    <div class="dots">
      <div :style="style" class="bounce1"></div>
      <div :style="style" class="bounce2"></div>
      <div :style="style" class="bounce3"></div>
    </div>
    <div class="overlay"
         v-if="overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    stretched: {
      required: false,
      default: false
    },
    overlay: {
      required: false,
      default: false
    },
    color: {
      required: false,
      type: String
    }
  },

  computed: {
    style () {
      return {
        backgroundColor: this.color
      }
    },

    classes () {
      return [
        { 'is-stretched': this.stretched }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-loader-dots {
    @include flex-center;

    flex-direction: column;

    margin: 0 auto;

    &.is-stretched {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      @include flex-center;
    }

    & > .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.15);
      opacity: 0.15
    }

    .dots {
      width: 70px;

      & > div {
        width: 10px;
        height: 10px;
        background-color: #333;

        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      }

      & .bounce1 {
        animation-delay: -0.32s;
      }

      & .bounce2 {
        animation-delay: -0.16s;
      }
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1.0);
      }
    }
  }
</style>
