<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m90.473 5.0391h-28.684c-2.3008 0-4.1641 1.8633-4.1641 4.1641s1.8633 4.1641 4.1641 4.1641h19.094l-40.863 40.863c-1.6289 1.625-1.6289 4.2617 0 5.8906 0.8125 0.8125 1.8789 1.2188 2.9453 1.2188s2.1328-0.40625 2.9453-1.2188l40.398-40.402v18.172c0 2.3008 1.8633 4.1641 4.1641 4.1641 2.3008 0 4.1641-1.8633 4.1641-4.1641v-28.688c0-2.3008-1.8633-4.1641-4.1641-4.1641z"/>
      <path d="m76.07 46.977c-2.3008 0-4.1641 1.8633-4.1641 4.1641v32.852c0 1.5039-1.2695 2.7773-2.7773 2.7773h-53.09c-1.5039 0-2.7773-1.2695-2.7773-2.7773v-53.09c0-1.5039 1.2695-2.7773 2.7773-2.7773h32.969c2.3008 0 4.1641-1.8633 4.1641-4.1641 0-2.3008-1.8633-4.1641-4.1641-4.1641h-32.969c-6.1211 0-11.105 4.9805-11.105 11.105v53.09c0 6.1211 4.9805 11.105 11.105 11.105h53.09c6.1211 0 11.105-4.9805 11.105-11.105v-32.852c0-2.3008-1.8633-4.1641-4.1641-4.1641z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
