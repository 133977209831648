<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 22 18"
    @click="$emit('click')"
    fill="none"
  >
    <path d="M1 11C1 13.8284 1 15.2426 1.87868 16.1213C2.75736 17 4.17157 17 7 17H15C17.8284 17 19.2426 17 20.1213 16.1213C21 15.2426 21 13.8284 21 11L21 7.00001C21 4.17158 21 2.75736 20.1213 1.87868C19.2426 1 17.8284 1 15 1.00001L6.99999 1.00002C4.17157 1.00002 2.75736 1.00002 1.87868 1.8787C1 2.75738 1 4.17159 1 7.00002V11Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.1679 6.74808C10.5638 6.15434 11.4362 6.15434 11.8321 6.74808L13.9635 9.9453C14.4066 10.6099 13.9302 11.5 13.1315 11.5H8.86852C8.06982 11.5 7.59343 10.6099 8.03647 9.9453L10.1679 6.74808Z" fill="white" opacity="0.5"/>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
