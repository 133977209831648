export default {
  state: {
    series: []
  },

  mutations: {
    setSeries (state, data) {
      state.series = data
    }
  },

  getters: {
    getSeries (state) {
      return state.series
    }
  }
}
