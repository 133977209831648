<template>
  <div class="BaseExpander">
    <div class="header" @click="open = !open">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <icon-dropdown class="icon"
                     :class="{ open }"
                     width="24"
                     height="24" />
    </div>
    <div class="content" v-show="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseExpander {
    margin-bottom: 20px;

    .header {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;

      .icon {
        transition: transform 0.3s;

        &.open {
          transform: rotate(180deg);
        }
      }

      .title {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
</style>
