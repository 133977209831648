<template>
  <validation-provider ref="provider"
                       :rules="rules"
                       :vid="vid"
                       :name="name"
                       :mode="validationInteractionMode"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-text-box"
         :class="getClasses(classes)">
      <label class="label"
             v-if="label"
             @click="focus">{{ label }}</label>

      <div class="input-wrapper">
        <input :id="id"
               ref="input"
               class="input"
               :name="name"
               :type="internalType"
               :value="value"
               :disabled="disabled"
               :autocomplete="autocomplete"
               :placeholder="placeholder"
               :inputmode="inputmode"
               :pattern="pattern"

               @input="onInput"
               @change="onChange"
               @blur="onBlur"
               @accept="onMaskAccept" />

        <div class="password-toggle"
           v-if="showPasswordToggle"
           @click="togglePassword">
          <icon-eye v-if="internalType === 'password'"
                    width="19"
                    heigh="19"
                    color="#BCBFBD" />
          <icon-eye-off v-if="internalType === 'text'"
                        width="19"
                        heigh="19"
                        color="#BCBFBD" />
        </div>
      </div>

      <div class="error"
           v-show="showErrors && errors.length > 0">
        {{ errors[0] }}
      </div>

      <div class="error"
           v-show="showErrors && customErrors.length > 0">
        {{ customErrors[0] }}
      </div>

      <div class="description"
           v-if="description">
        {{ description }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
// TODO: add focus and blur events @see https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314
export default {
  props: {
    id: {
      required: false,
      type: String
    },

    vid: {
      required: false,
      type: String
    },

    type: {
      required: false,
      type: String,
      default: 'text'
    },

    value: {
      required: true
    },

    name: {
      required: false,
      type: String
    },

    label: {
      required: false,
      type: String
    },

    description: {
      required: false,
      type: String
    },

    rules: {
      required: false,
      type: [String, null], // todo: why null?
      default: ''
    },

    validationInteractionMode: {
      required: false,
      type: String
      // default: 'lazy'
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    },

    showPasswordToggle: {
      required: false,
      type: Boolean,
      default: false
    },

    inputmode: {
      required: false,
      type: String,
      default: 'text'
    },

    pattern: {
      required: false,
      type: String,
      default: null
    },

    placeholder: {
      required: false,
      type: String,
      default: null
    },

    autocomplete: {
      required: false,
      type: String,
      default: 'off'
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    size: {
      required: false,
      type: String,
      default: 'normal'
    },

    bordered: {
      required: false,
      type: Boolean,
      default: true
    },

    maxLength: {
      required: false,
      type: Number,
      default: 0
    },

    customErrors: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      internalType: this.type
    }
  },

  methods: {
    togglePassword () {
      if (this.internalType === 'password') {
        this.internalType = 'text'
      } else {
        this.internalType = 'password'
      }
    },

    getClasses (validationClasses) {
      let displayValidation = false

      if (this.$refs.provider) {
        const required = this.$refs.provider.flags.required

        if (required) {
          displayValidation = true
        } else {
          // Okay to use == instead of ===
          // @see https://stackoverflow.com/questions/2647867/how-can-i-determine-if-a-variable-is-undefined-or-null
          if (this.value === '' || this.value == null || !this.rules) {
            displayValidation = false
          } else {
            displayValidation = true
          }
        }
      }

      return {
        ...validationClasses,
        'display-validation': displayValidation,
        'has-password-toggle': !!this.showPasswordToggle,
        'disabled': this.disabled,
        [this.size]: true,
        'bordered': this.bordered
      }
    },

    onInput (event) {
      if (!this.maxLength || event.target.value.length <= this.maxLength) {
        this.$emit('input', event.target.value)
      }
    },

    onChange (event) {
      this.$emit('change', event.target.value)
    },

    onMaskAccept (event) {
      this.$emit('input', event.target.value)
    },

    onBlur () {
      this.$emit('blur', this.value)
    },

    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-text-box {
    position: relative;
    margin-bottom: 30px;

    .input {
      width: 100%;
      padding: 0 16px;
      height: 44px;

      background: #fff;

      border: 1px solid transparent;
      background: #FFFFFF;
      border-radius: 6px;

      outline: none;

      font-size: 14px;
      font-weight: 500;
      transition: all 0.2s;

      &:hover,
      &:focus {
        box-shadow: 0px 3px 5px rgba(101, 81, 224, 0.15);
        border-color: map-get($colors, purple-1);
      }

      &::placeholder {
        color: #a4a3a7;
        font-weight: 400;
      }
    }

    &.bordered .input {
      border: 1px solid map-get($colors, border);
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

      &:hover,
      &:focus {
        box-shadow: 0px 3px 5px rgba(101, 81, 224, 0.15);
        border-color: map-get($colors, purple-1);
      }
    }

    .label {
      display: block;
      margin-bottom: 10px;
      //color: map-get($colors, gray-1);
      // font-size: 15px;
      // font-weight: 400;
      // letter-spacing: -0.009em;
      opacity: .8;

      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }

    .input-wrapper {
      position: relative;
    }

    .password-toggle {
      position: absolute;
      right: 0;
      top: 0;

      @include flex-center;

      width: 44px;
      height: 100%;
      cursor: pointer;

      svg {
        width: 19px;
        height: 19px;
        transition: .2s;
      }

      &:hover svg * {
        fill: map-get($colors, purple-1);
      }
    }

    .description {
      margin-top: 10px;
      font-size: 12px;
      opacity: .6;
      margin-left: 1px;
      line-height: 17px;
    }

    &.small {
      .input {
        height: 30px;
        font-size: 14px;
      }
    }

    &.has-password-toggle .input {
      padding-right: 50px;
    }

    &.display-validation {
      &.invalid .input {
        border-color: map-get($colors, red-2);
      }

      &.valid .input {
        border-color: map-get($colors, green-1);
      }
    }

    &.disabled .input {
      background-color: #f5f5f5;
    }

    .error {
      margin-top: 4px;
      color: map-get($colors, red-2);
    }
  }
</style>
