<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m67.051 19.309 6.8203-6.8203 13.641 13.641-6.8203 6.8203z"/>
      <path d="m12.5 73.855v13.645h13.625l47.73-47.73-13.625-13.645z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
