import usersApi from '@/api/usersApi.js'

export default {
  namespaced: true,

  state: {
    teammates: []
  },

  actions: {
    async load ({ commit }) {
      const teammates = await usersApi.getTeammates()
      commit('loadTeammates', teammates)
    },

    async deleteTeammate ({ commit }, id) {
      await usersApi.deleteTeammate(id)
      commit('deleteTeammate', id)
    },

    async addTeammate ({ commit }, data) {
      const teammate = await usersApi.addTeammate(data.email, data.role)
      commit('addTeammate', teammate)
    },

    updateTeammate ({ commit }, data) {
      commit('updateTeammate', data)
    }
  },

  mutations: {
    loadTeammates (state, teammates) {
      state.teammates = teammates
    },

    deleteTeammate (state, id) {
      const index = state.teammates.findIndex(teammate => teammate.id === id)
      state.teammates.splice(index, 1)
    },

    addTeammate (state, teammate) {
      state.teammates = [ ...state.teammates, teammate ]
    },

    updateTeammate (state, updatedTeammate) {
      const index = state.teammates.findIndex(teammate => teammate.id === updatedTeammate.id)
      state.teammates[index] = updatedTeammate
    }
  }
}
