<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m77.918 40.832l-27.918-27.914-27.918 27.914 5.8359 5.8359 17.914-17.918v58.75h8.3359v-58.75l17.914 17.918z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
