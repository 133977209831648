<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <g opacity="0.5">
      <path d="M5.92848 6.12861C5.72336 5.61583 5.14139 5.36641 4.62861 5.57152C4.11583 5.77664 3.86641 6.35861 4.07152 6.87139L5.92848 6.12861ZM7 11.5L6.07152 11.8714C6.22339 12.251 6.5911 12.5 7 12.5C7.4089 12.5 7.77661 12.251 7.92848 11.8714L7 11.5ZM9 6.5L9.92848 6.12861C9.77661 5.74895 9.4089 5.5 9 5.5C8.5911 5.5 8.22339 5.74895 8.07152 6.12861L9 6.5ZM11 11.5L10.0715 11.8714C10.2234 12.251 10.5911 12.5 11 12.5C11.4089 12.5 11.7766 12.251 11.9285 11.8714L11 11.5ZM13.9285 6.87139C14.1336 6.35861 13.8842 5.77664 13.3714 5.57152C12.8586 5.36641 12.2766 5.61583 12.0715 6.12861L13.9285 6.87139ZM4.07152 6.87139L6.07152 11.8714L7.92848 11.1286L5.92848 6.12861L4.07152 6.87139ZM7.92848 11.8714L9.92848 6.87139L8.07152 6.12861L6.07152 11.1286L7.92848 11.8714ZM8.07152 6.87139L10.0715 11.8714L11.9285 11.1286L9.92848 6.12861L8.07152 6.87139ZM11.9285 11.8714L13.9285 6.87139L12.0715 6.12861L10.0715 11.1286L11.9285 11.8714ZM4.11111 2H13.8889V0H4.11111V2ZM16 4.11111V13.8889H18V4.11111H16ZM13.8889 16H4.11111V18H13.8889V16ZM2 13.8889V4.11111H0V13.8889H2ZM4.11111 16C2.94518 16 2 15.0548 2 13.8889H0C0 16.1594 1.84061 18 4.11111 18V16ZM16 13.8889C16 15.0548 15.0548 16 13.8889 16V18C16.1594 18 18 16.1594 18 13.8889H16ZM13.8889 2C15.0548 2 16 2.94518 16 4.11111H18C18 1.84061 16.1594 0 13.8889 0V2ZM4.11111 0C1.84061 0 0 1.84061 0 4.11111H2C2 2.94518 2.94518 2 4.11111 2V0Z" fill="#1B1823"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
