import tippy from 'tippy.js'

export default {
  inserted (el, { value }) {
    if (!value.content) {
      return
    }

    tippy(el, {
      content: value.content
    })
  }
}
