<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 7 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path d="M3.06811 0.740381C3.26105 0.409629 3.73895 0.409629 3.93189 0.740382L6.56137 5.24806C6.75581 5.58139 6.51538 6 6.12948 6H0.870518C0.484623 6 0.244187 5.58139 0.438629 5.24806L3.06811 0.740381Z" fill="white"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
