<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 512 512"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="M324.7745 269.3811l13.9177-30.1075 58.6093 27.0932-13.9177 30.1075z"/>
      <path d="M265.4 126.618c-50 0-90.678 40.678-90.678 90.678s40.678 90.678 90.678 90.678 90.673-40.678 90.673-90.678-40.673-90.678-90.673-90.678zm0 148.181c-31.704 0-57.503-25.794-57.503-57.503 0-31.704 25.794-57.503 57.503-57.503 31.704 0 57.498 25.794 57.498 57.503-.001 31.704-25.794 57.503-57.498 57.503zM145.417 0c-38.112 0-69.115 31.002-69.115 69.114 0 38.112 31.002 69.115 69.115 69.115 38.107 0 69.114-31.002 69.114-69.115C214.531 31.002 183.529 0 145.417 0zm0 105.054c-19.817 0-35.94-16.123-35.94-35.94 0-19.816 16.123-35.94 35.94-35.94 19.816 0 35.94 16.123 35.94 35.94-.001 19.817-16.124 35.94-35.94 35.94zM411.922 14.929c-38.112 0-69.114 31.002-69.114 69.115s31.002 69.114 69.114 69.114 69.115-31.008 69.115-69.114c0-38.108-31.002-69.115-69.115-69.115zm0 105.054c-19.816 0-35.94-16.123-35.94-35.94 0-19.817 16.123-35.94 35.94-35.94 19.817 0 35.94 16.123 35.94 35.94 0 19.817-16.123 35.94-35.94 35.94zM73.538 225.037c-38.112 0-69.114 31.002-69.114 69.115s31.002 69.114 69.114 69.114c38.107 0 69.115-31.002 69.115-69.114s-31.003-69.115-69.115-69.115zm0 105.054c-19.816 0-35.94-16.123-35.94-35.94 0-19.816 16.123-35.94 35.94-35.94 19.817 0 35.94 16.123 35.94 35.94-.001 19.817-16.124 35.94-35.94 35.94zM268.164 373.771c-38.112 0-69.114 31.002-69.114 69.114 0 38.112 31.002 69.115 69.114 69.115s69.115-31.002 69.115-69.115c0-38.112-31.002-69.114-69.115-69.114zm0 105.054c-19.816 0-35.939-16.123-35.939-35.94 0-19.816 16.123-35.94 35.939-35.94 19.817 0 35.94 16.123 35.94 35.94 0 19.817-16.123 35.94-35.94 35.94zM438.462 233.883c-38.112 0-69.115 31.002-69.115 69.115s31.002 69.114 69.115 69.114c38.107 0 69.114-31.002 69.114-69.114s-31.001-69.115-69.114-69.115zm0 105.054c-19.817 0-35.94-16.123-35.94-35.94 0-19.817 16.123-35.94 35.94-35.94 19.816 0 35.94 16.123 35.94 35.94 0 19.817-16.123 35.94-35.94 35.94z"/>
      <path d="M117.0274 261.6792l72.9928-29.8564 12.5605 30.7077-72.9928 29.8564zM246.0527 290.7359l33.1607-.9123 2.7683 100.6268-33.1607.9122zM304.8024 146.973l55.8417-40.9224 19.6098 26.759-55.8417 40.9224zM167.4829 118.9483l25.5587-21.153 39.813 48.1053-25.5587 21.153z"/><path d="m75 64.062c-3.668 0-6.9141 1.8203-8.8984 4.6016l-30.727-15.227c0.35938-1.0859 0.5625-2.2344 0.5625-3.4375 0-1.2461-0.21875-2.4414-0.60547-3.5586l30.691-15.211c1.9766 2.8398 5.2617 4.707 8.9766 4.707 6.0312 0 10.938-4.9062 10.938-10.938s-4.9062-10.938-10.938-10.938-10.938 4.9062-10.938 10.938c0 1.2031 0.20312 2.3516 0.5625 3.4375l-30.727 15.227c-1.9844-2.7812-5.2305-4.6016-8.8984-4.6016-6.0312 0-10.938 4.9062-10.938 10.938s4.9062 10.938 10.938 10.938c3.7148 0 7-1.8672 8.9766-4.707l30.691 15.211c-0.38672 1.1172-0.60547 2.3125-0.60547 3.5586 0 6.0312 4.9062 10.938 10.938 10.938s10.938-4.9062 10.938-10.938-4.9062-10.938-10.938-10.938zm0-46.875c4.3086 0 7.8125 3.5039 7.8125 7.8125s-3.5039 7.8125-7.8125 7.8125c-3.0117 0-5.6016-1.7305-6.9062-4.2344-0.015625-0.035156-0.011719-0.070313-0.027344-0.10156-0.023437-0.046874-0.066406-0.078124-0.097656-0.125-0.48438-1.0234-0.78125-2.1484-0.78125-3.3516 0-4.3086 3.5039-7.8125 7.8125-7.8125zm-50 40.625c-4.3086 0-7.8125-3.5039-7.8125-7.8125s3.5039-7.8125 7.8125-7.8125 7.8125 3.5039 7.8125 7.8125c0 1.2031-0.29688 2.332-0.78516 3.3516-0.027344 0.042969-0.070313 0.074219-0.09375 0.12109-0.015625 0.03125-0.015625 0.070313-0.027344 0.10156-1.3047 2.5039-3.8945 4.2383-6.9062 4.2383zm50 25c-4.3086 0-7.8125-3.5039-7.8125-7.8125s3.5039-7.8125 7.8125-7.8125 7.8125 3.5039 7.8125 7.8125-3.5039 7.8125-7.8125 7.8125z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
