import auth from '@/services/auth.js'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: null,
    google_and_traditional_account: false
  },

  actions: {
    async login ({ commit }, credentials) {
      const data = await auth.login(credentials)

      if (data.tfa && data.tfa === true) {
        return data
      }
      // Todo: refactor copy-pasting
      commit('setAuthenticated', true)
      commit('setUser', data.user)
      commit('setToken', data.access_token)
    },

    async register ({ commit }, credentials) {
      const response = await auth.register(credentials)

      // Todo: refactor copy-pasting
      commit('setAuthenticated', true)
      commit('setUser', response.data.user)
      commit('setToken', response.data.access_token)

      return response
    },

    async loginViaGoogle ({ commit }, authCode) {
      const data = await auth.googleAuth(authCode)

      if (data.tfa && data.tfa === true) {
        return data
      }
      if (data.user && data.user.sign_in_method === 'traditional') {
        commit('setGoogleAndTraditionalAccount', true)
      }

      // Todo: refactor copy-pasting
      commit('setAuthenticated', true)
      commit('setUser', data.user)
      commit('setToken', data.access_token)
    },

    async refreshCurrentUser ({ commit }, query = '') {
      const user = await auth.me(query)

      commit('setUser', user)
    },

    logout () {
      auth.logout()
    }
  },

  mutations: {
    setAuthenticated (state, authenticated) {
      state.authenticated = authenticated
    },

    setUser (state, user) {
      state.user = user
    },

    setToken (state, token) {
      state.token = token
    },

    setGoogleAndTraditionalAccount (state, google_and_traditional_account) {
      state.google_and_traditional_account = google_and_traditional_account
    }
  }
}
