<template>
  <svg :width="width"
       :height="height"
       viewBox="0 0 22 22"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       @click="$emit('click')"
  >
    <circle cx="11" cy="11" r="11" :fill="fillColor"/>
    <path d="M14 8L8 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    <path d="M8 8L14 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
