<template>
  <label class="base-checkbox"
         :class="classes">
    <input type="checkbox"
           :disabled="disabled"
           v-model="isChecked"
           @change="onChange"
           @focus="onFocus"
           @blur="onBlur" />

    <div class="checkmark"></div>

    <div class="title" v-if="label || $slots.default">
      <slot>{{ label }}</slot>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [Boolean, Number]
    },

    label: {
      required: false,
      type: String
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isChecked: this.value
    }
  },

  methods: {
    onChange () {
      this.$emit('input', this.isChecked)
    },

    onFocus (event) {
      this.isActive = true
      this.$emit('focus', event)
    },

    onBlur (event) {
      this.isActive = false
      this.$emit('blur', event)
    }
  },

  computed: {
    classes () {
      return [
        { 'is-checked': this.isChecked },
        { 'is-disabled': this.disabled }
      ]
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.isChecked = !!value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-checkbox {
    display: flex; // was inline-flex, not sure why
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;

    & input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .title {
      display: flex;
      align-items: center;
      margin-left: 10px;
      letter-spacing: 0;
      // user-select: none;
    }

    .checkmark {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      background: #fff;
      border: 1px solid map-get($colors, border);
      flex-shrink: 0;
      transition: .15s;
    }

    .checkmark:after {
      // position: absolute;
      // display: none;
      // content: '';
      // top: 50%;
      // left: 50%;
      // width: 6px;
      // height: 10px;
      // transform: translate(-40%, -65%) rotate(45deg) scale(1.1);
      // border: solid #fff;
      // border-width: 0 2px 2px 0;
      // border-radius: 2px;

      content: "✓";
      position: absolute;
      display: none;
      top: 0;
      color: #fff;
      left: 0;
      width: 16px;
      font-size: 16px;
      text-align: center;
      height: 16px;
      font-weight: 500;
      line-height: 15px;
    }

    &.is-checked {
      .checkmark {
        background-color: map-get($colors, purple-1);
        border-color: darken(map-get($colors, purple-1), 5%);

        &:after {
          display: block;
        }
      }
    }

    &:not(.is-disabled):not(.is-checked):hover,
    &:not(.is-disabled):not(.is-checked).is-active {
      .checkmark {
        border-color: map-get($colors, purple-1);
      }
    }

    &.is-disabled {
      cursor: default;

      .checkmark {
        pointer-events: none;
        opacity: 1;
        color: rgba(228, 232, 237, 0.5);
        border: 1px solid #E4E8ED;
        cursor: not-allowed;
        background: #e9e9e9;
      }

      .checkmark:after {
        border-color: #7b7b7b;
      }
    }
  }
</style>
