<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 19 21"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="M13.6397 4.96122C13.6397 7.48756 11.584 9.52254 9.06423 9.52254C6.54447 9.52254 4.48874 7.48756 4.48874 4.96122C4.48874 2.43488 6.54447 0.399902 9.06423 0.399902C11.584 0.399902 13.6397 2.43488 13.6397 4.96122ZM9.06423 7.92254C10.7076 7.92254 12.0397 6.59671 12.0397 4.96122C12.0397 3.32573 10.7076 1.9999 9.06423 1.9999C7.42091 1.9999 6.08874 3.32573 6.08874 4.96122C6.08874 6.59671 7.42091 7.92254 9.06423 7.92254Z" />
      <path d="M6.0577 9.92691C6.25805 10.3207 6.10123 10.8024 5.70743 11.0027C3.45244 12.15 1.87225 14.414 1.69758 17.0552C1.69669 17.0688 1.69965 17.0788 1.70389 17.0864C1.70815 17.094 1.7153 17.1022 1.72789 17.1089L1.35152 17.8148L1.72789 17.1089C2.72664 17.6414 5.41619 18.861 9.06947 18.861C12.7224 18.861 15.4051 17.6416 16.4006 17.1094C16.413 17.1028 16.4201 17.0947 16.4243 17.0871C16.4285 17.0796 16.4315 17.0697 16.4306 17.0561C16.2562 14.4145 14.6759 12.1501 12.4207 11.0027C12.0269 10.8024 11.87 10.3207 12.0704 9.92691C12.2707 9.53312 12.7524 9.3763 13.1462 9.57665C15.8849 10.97 17.8141 13.7247 18.0271 16.9507C18.0701 17.6023 17.7297 18.2132 17.1549 18.5204C16.0177 19.1284 13.0719 20.461 9.06947 20.461C5.06704 20.461 2.11474 19.1283 0.97514 18.5207C0.399452 18.2138 0.0579102 17.6023 0.101071 16.9497C0.314381 13.7241 2.24348 10.9699 4.98192 9.57665C5.37571 9.3763 5.85735 9.53312 6.0577 9.92691Z" />
    </g>
  </svg>
</template>

<script>
import iconMixin from '@/components/Icons/iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
