<template>
  <div id="app">
    <router-view/>
    <div v-show="false">{{ version }}</div>
  </div>
</template>

<script>
export default {
  metaInfo () {
    if (!(location.hostname).includes('pulsetic.com') || (location.href).includes('pulsetic.com/status/')) {
      return {
        title: null,
        titleTemplate: null
      }
    } else {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: `${this.appName} | Uptime Monitoring Service`,

        // all titles will be injected into this template
        titleTemplate: `%s | ${this.appName}`
      }
    }
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME,
      version: process.env.VUE_APP_GIT_COMMIT
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style src="./assets/scss/app.scss" lang="scss"></style>
