<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m85 18c2.7617 0 5 2.2383 5 5s-2.2383 5-5 5h-70c-2.7617 0-5-2.2383-5-5s2.2383-5 5-5zm0 27c2.7617 0 5 2.2383 5 5s-2.2383 5-5 5h-70c-2.7617 0-5-2.2383-5-5s2.2383-5 5-5zm0 27c2.7617 0 5 2.2383 5 5s-2.2383 5-5 5h-70c-2.7617 0-5-2.2383-5-5s2.2383-5 5-5z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
