import nodeTransformer from '@/services/transformers/nodeTransformer.js'

const baseURL = 'nodes'

export default {
  /**
   * Get node list.
   *
   * @param params
   * @returns Promise
   */
  get (params = {}) {
    return axios.get(`${baseURL}/`, {
      params
    }).then(response => response.data.data.map(nodeTransformer))
  }
}
