<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <g opacity="0.5">
      <path d="M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12H8ZM6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7V5ZM12 7C12.5523 7 13 6.55228 13 6C13 5.44772 12.5523 5 12 5V7ZM8 6V12H10V6H8ZM6 7H9V5H6V7ZM9 7H12V5H9V7ZM4.11111 2H13.8889V0H4.11111V2ZM16 4.11111V13.8889H18V4.11111H16ZM13.8889 16H4.11111V18H13.8889V16ZM2 13.8889V4.11111H0V13.8889H2ZM4.11111 16C2.94518 16 2 15.0548 2 13.8889H0C0 16.1594 1.84061 18 4.11111 18V16ZM16 13.8889C16 15.0548 15.0548 16 13.8889 16V18C16.1594 18 18 16.1594 18 13.8889H16ZM13.8889 2C15.0548 2 16 2.94518 16 4.11111H18C18 1.84061 16.1594 0 13.8889 0V2ZM4.11111 0C1.84061 0 0 1.84061 0 4.11111H2C2 2.94518 2.94518 2 4.11111 2V0Z" fill="#1B1823"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
