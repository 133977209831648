<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 20 20"
       @click="$emit('click')">
    <path opacity="0.7" d="M5 8L10 13L15 8" stroke="black" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
