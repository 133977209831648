<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 30 30"
       @click="$emit('click')"
  >
      <circle cx="15" cy="15" r="15" fill="#A3A7A5"/>
      <path d="M15 9.5V16.5" stroke="white" stroke-width="2" stroke-linecap="round"/>
      <path d="M15 21.5C15.5523 21.5 16 21.0523 16 20.5C16 19.9477 15.5523 19.5 15 19.5C14.4477 19.5 14 19.9477 14 20.5C14 21.0523 14.4477 21.5 15 21.5Z" fill="white"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
