<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 19 19"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 3C5.18182 3 1.46818 5.68667 0 9.5C1.46818 13.3133 5.18182 16 9.5 16C13.8182 16 17.5318 13.3133 19 9.5C17.5318 5.68667 13.8182 3 9.5 3ZM9.5 14C6.98 14 5 12.02 5 9.5C5 6.98 6.98 5 9.5 5C12.02 5 14 6.98 14 9.5C14 12.02 12.02 14 9.5 14ZM9.5 7C8.08333 7 7 8.08333 7 9.5C7 10.9167 8.08333 12 9.5 12C10.9167 12 12 10.9167 12 9.5C12 8.08333 10.9167 7 9.5 7Z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
