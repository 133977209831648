<template>
  <div class="BaseTextboxSearch"
       :class="classes">
    <div class="position-relative">
      <input :id="id"
             type="text"
             ref="input"
             class="input"
             :value="value"
             :disabled="disabled"
             :autocomplete="autocomplete"
             :placeholder="placeholder"
             @input="onInput"
             @change="onChange"
             @blur="onBlur" />
      <icon-search class="icon-search" width="22" height="22" @click="searchClick"/>
    </div>
  </div>
</template>

<script>
// TODO: add focus and blur events @see https://medium.com/@logaretm/authoring-validatable-custom-vue-input-components-1583fcc68314
export default {
  props: {
    id: {
      required: false,
      type: String
    },

    value: {
      required: true
    },

    placeholder: {
      required: false,
      type: String,
      default: null
    },

    autocomplete: {
      required: false,
      type: String,
      default: 'off'
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    size: {
      required: false,
      type: String,
      default: 'normal'
    },

    bordered: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      internalType: this.type
    }
  },

  methods: {
    onInput (event) {
      this.$emit('input', event.target.value)
    },

    onChange (event) {
      this.$emit('change', event.target.value)
    },

    onBlur () {
      this.$emit('blur', this.value)
    },

    focus () {
      this.$refs.input.focus()
    },

    searchClick () {
      this.$emit('blur', this.value)
    }
  },

  computed: {
    classes () {
      return {
        'disabled': this.disabled,
        [this.size]: true,
        'bordered': this.bordered
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseTextboxSearch {
    position: relative;

    .input {
      width: 100%;
      min-width: 250px;
      padding: 0 8px 0 14px;
      height: 40px;
      line-height: 40px;

      background: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      outline: none;

      font-size: 14px;
      font-weight: 500;
      color: map-get($colors, gray-1);
      transition: all 0.2s;

      &:hover,
      &:focus {
        box-shadow: 0px 3px 5px rgba(47, 128, 237, 0.15);
        border-color: map-get($colors, purple-1);
      }

      &::placeholder {
        color: #a4a3a7;
        font-weight: 400;
      }
    }

    &.bordered .input {
      border: 1px solid map-get($colors, gray-3);

      &:focus {
        border: 1px solid map-get($colors, purple-1);
      }
    }

    &.small {
      .input {
        height: 30px;
        font-size: 14px;
      }
    }

    .icon-search {
      position: absolute;
      right: 10px;
      top: 9px;
      cursor: pointer;
    }
  }
</style>
