<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m15 24c-0.78516 0-1.5312 0.16406-2.2188 0.4375l34.812 29.625c1.5234 1.2969 3.2227 1.2969 4.75 0l34.844-29.656c-0.67578-0.26172-1.4141-0.40625-2.1875-0.40625zm-5.9375 5.125c-0.039062 0.28125-0.0625 0.58203-0.0625 0.875v40c0 3.3242 2.6758 6 6 6h70c3.3242 0 6-2.6758 6-6v-40c0-0.29297-0.023438-0.59375-0.0625-0.875l-34.688 29.5c-3.5977 3.0586-8.9688 3.0625-12.562 0z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
