<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 15 14"
       @click="$emit('click')">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75551 1.1C3.63206 1.1 1.1 3.63206 1.1 6.75551C1.1 9.87896 3.63206 12.411 6.75551 12.411C9.87896 12.411 12.411 9.87896 12.411 6.75551C12.411 6.47556 12.3907 6.20068 12.3516 5.9322C12.3078 5.63162 12.516 5.35244 12.8165 5.30864C13.1171 5.26484 13.3963 5.473 13.4401 5.77358C13.4869 6.09443 13.511 6.42231 13.511 6.75551C13.511 10.4865 10.4865 13.511 6.75551 13.511C3.02454 13.511 0 10.4865 0 6.75551C0 3.02454 3.02454 0 6.75551 0C8.22095 0 9.57889 0.467241 10.6863 1.26079C10.9333 1.43772 10.99 1.78131 10.8131 2.02822C10.6361 2.27513 10.2925 2.33186 10.0456 2.15494C9.1188 1.49081 7.98361 1.1 6.75551 1.1Z" :fill="fillColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.935 0.748474C14.1518 0.961209 14.1551 1.30943 13.9424 1.52626L7.56388 8.02727C7.46046 8.13268 7.31898 8.19208 7.17129 8.19208C7.02361 8.19208 6.88213 8.13268 6.7787 8.02727L4.29218 5.49298C4.07944 5.27615 4.08275 4.92793 4.29957 4.71519C4.5164 4.50246 4.86462 4.50577 5.07736 4.72259L7.17129 6.85675L13.1572 0.755873C13.3699 0.539051 13.7182 0.535738 13.935 0.748474Z" :fill="fillColor"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
