<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m72.082 53.332l-17.914 17.918v-58.75h-8.3359v58.75l-17.914-17.918-5.8359 5.8359 27.918 27.914 27.918-27.914z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
