<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <g clip-path="url(#clip0)">
        <path d="M-2 8C-0.00071422 8 -0.00071422 16 1.99857 16C3.99643 16 3.99643 8 5.995 8C7.995 8 7.995 16 9.995 16C11.9964 16 11.9964 8 13.9979 8C15.9993 8 15.9986 16 18 16" stroke="url(#paint0_linear)" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
        </g>
        <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
        <defs>
        <linearGradient id="paint0_linear" x1="16" y1="12" x2="-0.499999" y2="12" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0.5"/>
        </linearGradient>
        <clipPath id="clip0">
        <rect width="17" height="10" fill="white" transform="matrix(1 0 0 -1 2 17)"/>
        </clipPath>
        </defs>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
