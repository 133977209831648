<template>
  <div class="BaseFileUpload">
    <label class="label"
           v-if="label"
           @click="selectFile">{{ label }}</label>

    <div class="d-flex align-items-center">
      <base-button :color="color"
                   @click="selectFile">
        {{ text }}
      </base-button>

      <div class="description description mt-0 pl-3 pb-1 opacity-6"
           v-if="description">
        {{ description }}
      </div>
    </div>

    <input type="file"
           ref="file"
           @change="onFileChange"
           :multiple="multiple"
           hidden>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      type: String
    },

    defaultText: {
      required: false,
      type: String,
      default: 'Choose file'
    },

    description: {
      required: false,
      type: String
    },

    color: {
      required: false,
      type: String
    },

    multiple: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      files: []
    }
  },

  methods: {
    selectFile () {
      this.$refs.file.click()
    },

    onFileChange (event) {
      this.files = !this.files
      this.files = event.target.files
    }
  },

  watch: {
    files () {
      if (this.files.length > 0) {
        this.$emit('select', this.files)
      } else {
        this.$emit('select', null)
      }
    }
  },

  computed: {
    text () {
      if (this.files.length === 0) {
        return this.defaultText
      }

      if (this.files.length === 1) {
        return this.files[0].name
      }

      return `Selected ${this.files.length} files`
    }
  }
}
</script>

<style lang="scss" scoped>
  .BaseFileUpload {
    position: relative;
    margin-bottom: 20px;

    .label {
      display: block;
      // margin-bottom: 6px;
      // color: map-get($colors, gray-1);
      // font-size: 14px;
      // font-weight: 600;
      opacity: .8;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-feature-settings: "ss04" off;
    }

    .description {
      margin-top: 4px;
      font-size: 12px;
      line-height: 17px;
      color: map-get($colors, gray-1);
      max-width: 180px;
    }
  }
</style>
