import store from '@/store/index.js'

export default {
  /**
   * Login user using traditional email & password credentials.
   *
   * @param credentials
   * @returns Object
   */
  async login (credentials) {
    const response = await axios.post('auth/login', credentials)

    return response.data
  },

  /**
   * Autologin user based on the email. The Bearer token has to be present or the call will not function
   *
   * @returns Object
   * @param email
   */
  async autologin (email) {
    const response = await axios.post('auth/autologin', { email: email })
    return response.data
  },

  /**
   * Register new user.
   *
   * @param credentials
   * @returns Object
   */
  async register (credentials) {
    const response = await axios.post('auth/register', credentials)

    return response
  },

  /**
   * Send reset password link.
   *
   * @param credentials
   * @returns boolean
   */
  async sendResetPasswordLink (credentials) {
    try {
      const response = await axios.post('auth/send-reset-password-link', credentials)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },

  /**
   * Reset password.
   *
   * @param credentials
   * @returns boolean
   */
  async resetPassword (credentials) {
    try {
      const response = await axios.post('auth/reset-password', credentials)
      return response.data.success
    } catch {
      return false
    }
  },

  async verifyEmail (data) {
    try {
      const response = await axios.post('auth/verify-email', data,
        { headers: { Authorization: '', Accept: 'application/json' } })
      return response.data
    } catch {
      return false
    }
  },

  resendConfirmationEmail () {
    return axios.post('auth/resend-verification-email')
  },

  /**
   * Get current user.
   *
   * @returns Object
   */
  async me (query) {
    let queryString = ''
    if (query) {
      queryString = `?query=${query}`
    }
    const response = await axios.get(`auth/me${queryString}`)

    return response.data.data
  },

  /**
   * Login user using google auth code.
   *
   * @param authCode
   * @returns Object
   */
  async googleAuth (authCode) {
    const response = await axios.post('/auth/google-auth', { authCode })

    return response.data
  },

  /**
   * Logout.
   */
  logout () {
    store.commit('authentication/setAuthenticated', false)
    store.commit('authentication/setUser', null)
    store.commit('authentication/setToken', null)
    store.commit('authentication/setGoogleAndTraditionalAccount', false)
  },

  /**
   * Check if user is authenticated.
   *
   * @returns {boolean}
   */
  isAuthenticated () {
    return store.state.authentication.authenticated
  },

  /**
   * Get JWT token.
   *
   * @returns {null|CancelToken}
   */
  getToken () {
    return store.state.authentication.token
  },

  /**
   * Get authenticated user.
   * @returns {Object | null}
   */
  getUser () {
    return store.state.authentication.user
  },

  enableTfa () {
    return axios.get('/auth/2fa/enable').then(
      response => response.data
    )
  },

  disableTfa () {
    return axios.get('/auth/2fa/disable').then(
      response => response.data
    )
  },

  getQr () {
    return axios.get('/auth/2fa/get-qr').then(
      response => response.data
    )
  },

  validateOneTimeCode (oneTimeCode, token, loginType) {
    return axios.post('/auth/2fa/validate', { oneTimeCode, token, loginType }).then(
      response => response.data
    )
  }
}
