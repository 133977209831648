<template>
  <div class="base-alert" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      required: false,
      default: 'info'
    }
  },

  computed: {
    classes () {
      return [
        { 'alert-info': this.type === 'info' },
        { 'alert-error': this.type === 'error' },
        { 'alert-success': this.type === 'success' },
        { 'alert-warning': this.type === 'warning' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-alert {
    position: relative;
    padding: 16px 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    line-height: 1.5;
    background: #BDBDBD;
  }

  .alert-info {
    color: #333;
    background: rgba(86, 204, 242, 0.1);
  }

  .alert-error {
    color: #333;
    background: rgba(235, 87, 87, 0.1);
  }

  .alert-success {
    color: #333;
    background: rgba(39, 174, 96, 0.1);
  }

  .alert-warning {
    color: #333;
    background: rgba(242, 153, 74, 0.1);
  }
</style>
