<template>
  <div class="BaseSlider">
    <label class="label"
           v-if="label">{{ label }}</label>

    <vue-slider v-model="valueCopy"
                :min="min"
                :max="max"
                :interval="interval"
                :tooltip="tooltip" />

    <div class="description"
         v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
  components: {
    VueSlider
  },

  props: {
    value: {
      required: true
    },

    label: {
      required: false,
      type: String
    },

    description: {
      required: false,
      type: String
    },

    min: {
      required: false
    },

    max: {
      required: false
    },

    interval: {
      required: false
    },

    tooltip: {
      required: false
    }
  },

  data () {
    return {
      valueCopy: this.value
    }
  },

  watch: {
    valueCopy () {
      this.$emit('input', this.valueCopy)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~vue-slider-component/theme/default.css';

  .BaseSlider {
    margin-bottom: 20px;
    filter: hue-rotate(36deg) saturate(3) contrast(1.3); //help me to fix this with styles (line #105 doesn't work)

    .label {
      color: map-get($colors, gray-1);
      margin-bottom: 10px;
      opacity: .8;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: .05em;
      text-transform: uppercase;
      -webkit-font-feature-settings: "ss04" off;
      font-feature-settings: "ss04" off;
    }

    .description {
      // margin-top: 4px;
      // font-size: 14px;
      // line-height: 21px;
      color: map-get($colors, gray-1);
      margin-top: 5px;
      font-size: 12px;
      opacity: .6;
      margin-left: 1px;
      line-height: 17px;
    }

    .vue-slider {
      .vue-slider-rail .vue-slider-process {
        background-color: map-get($colors, purple-1);
      }
      .vue-slider-dot-tooltip-inner {
        background-color: map-get($colors, purple-1);
        border-color: map-get($colors, purple-1);
        &:after {
          background-color: map-get($colors, purple-1);
        }
      }
    }
  }
</style>
