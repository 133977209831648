<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <g clip-path="url(#clip0_1104_1390)">
      <path d="M1.20001 22.8002L4.20001 19.8002M12.5 14.5L11 16M9.5 11.5L8 13M4.5 19.5V19.5C6.15685 21.1569 8.84314 21.1569 10.5 19.5L11.3056 18.6944C11.6891 18.3109 11.6891 17.6891 11.3056 17.3056L6.69444 12.6944C6.31091 12.3109 5.68909 12.3109 5.30556 12.6944L4.5 13.5C2.84315 15.1569 2.84315 17.8431 4.5 19.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M23 1L20 4M19.5 4.5V4.5C17.8431 2.84315 15.1569 2.84315 13.5 4.5L12.6944 5.30556C12.3109 5.68909 12.3109 6.31092 12.6944 6.69445L17.3056 11.3056C17.6891 11.6891 18.3109 11.6891 18.6944 11.3056L19.5 10.5C21.1569 8.84315 21.1569 6.15686 19.5 4.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1104_1390">
        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
