<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path transform="translate(0 -952.36)" d="m22 1043.4l56-41-56-41" fill="none" :stroke="fillColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="8"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
