import Vue from 'vue'
import store from './store/index.js'
import auth from '@/services/auth.js'
import Echo from 'laravel-echo'

export const loadBaseComponents = () => {
  let requireComponent = require.context('@/components/Base/', true, /\.vue$/)

  requireComponent.keys().forEach((fileName) => {
    let baseComponentConfig = requireComponent(fileName)
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig

    let baseComponentName = baseComponentConfig.name || (
      fileName
        .replace(/^.+\//, '')
        .replace(/\.\w+$/, '')
    )

    Vue.component(baseComponentName, baseComponentConfig)
  })
}

export const loadIcons = () => {
  let requireComponent = require.context('@/components/Icons/', true, /\.vue$/)

  requireComponent.keys().forEach((fileName) => {
    let baseComponentConfig = requireComponent(fileName)
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig

    let baseComponentName = baseComponentConfig.name || (
      fileName
        .replace(/^.+\//, '')
        .replace(/\.\w+$/, '')
    )

    Vue.component(baseComponentName, baseComponentConfig)
  })
}

export const initPusher = () => {
  window.Pusher = import('pusher-js').then(() => {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      forceTLS: process.env.VUE_APP_PUSHER_FORCE_TLS,
      authEndpoint: `${process.env.VUE_APP_BASE_URL}broadcasting/auth`,
      auth: {
        headers: {
          'Authorization': `Bearer ${auth.getToken()}`
        }
      }
    })

    const userId = auth.getUser().id
    const channelName = `user.${userId}`

    window.Echo.private(channelName)
      .listen('.PaddleWebhookHandled', () => {
        store.dispatch('authentication/refreshCurrentUser')
      })
  })
}

export const bootstrap = async () => {
  await axios.all([
    store.dispatch('nodes/load'),
    store.dispatch('authentication/refreshCurrentUser'),
    store.dispatch('system/loadTags')
  ])

  initPusher()

  return true
}
