<template>
  <div class="BasePaginate">
    <div class="arrow"
         :class="{ disabled: prevDisabled }"
         @click="onPrevClick">
      <icon-prev-page width="14"
                      height="14"
                      color="#b2b6c3" />
    </div>
    <div class="arrow"
         :class="{ disabled: nextDisabled }"
         @click="onNextClick">
      <icon-next-page width="14"
                      height="14"
                      color="#b2b6c3" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      required: true,
      type: Number
    },

    skip: {
      required: true,
      type: Number
    },

    take: {
      required: true,
      type: Number
    }
  },

  methods: {
    onNextClick () {
      if (this.nextDisabled) {
        return false
      }

      this.$emit('next')
    },

    onPrevClick () {
      if (this.prevDisabled) {
        return false
      }

      this.$emit('prev')
    }
  },

  computed: {
    prevDisabled () {
      return this.skip - this.take < 0
    },

    nextDisabled () {
      return this.skip + this.take >= this.total
    }
  }
}
</script>

<style lang="scss" scoped>
  .BasePaginate {
    display: flex;
    justify-content: space-between;
    width: 76px;
    margin-left: 30px;

    .arrow {
      @include flex-center;

      padding: 8px;

      border-radius: 4px;
      background-color: #f6f5f7;
      transition: 0.2s all;

      &:hover:not(.disabled) {
        cursor: pointer;
        background-color: darken(#f6f5f7, 5%);
      }
    }
  }
</style>
