<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 19 19"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52018 14.0657L1.79297 15.7929L3.20718 17.2071L17.7072 2.70712L16.293 1.29291L13.6892 3.89669C12.4099 3.3203 10.991 3 9.5 3C5.18182 3 1.46818 5.68667 0 9.5C0.710058 11.3442 1.94533 12.925 3.52018 14.0657ZM5.67897 11.9069L7.16488 10.421C7.05806 10.1381 7 9.82797 7 9.5C7 8.08333 8.08333 7 9.5 7C9.82797 7 10.1381 7.05806 10.421 7.16488L11.9069 5.67897C11.2146 5.24754 10.391 5 9.5 5C6.98 5 5 6.98 5 9.5C5 10.391 5.24754 11.2146 5.67897 11.9069ZM9.5 16C8.70241 16 7.92545 15.9083 7.17945 15.7349L8.94664 13.9677C9.12771 13.989 9.31236 14 9.5 14C12.02 14 14 12.02 14 9.5C14 9.31236 13.989 9.12771 13.9677 8.94664L16.8196 6.09468C17.7587 7.06285 18.5063 8.21773 19 9.5C17.5318 13.3133 13.8182 16 9.5 16Z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
