<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       :viewBox="viewBox"
       @click="$emit('click')">
    <g :fill="fillColor">
      <path d="m50 3c-12.465 0-24.418 4.9531-33.234 13.766-8.8125 8.8164-13.766 20.77-13.766 33.234s4.9531 24.418 13.766 33.234c8.8164 8.8125 20.77 13.766 33.234 13.766s24.418-4.9531 33.234-13.766c8.8125-8.8164 13.766-20.77 13.766-33.234s-4.9531-24.418-13.766-33.234c-8.8164-8.8125-20.77-13.766-33.234-13.766zm-0.64062 77.922c-2.1758 0-4.1367-1.3125-4.9688-3.3242-0.83203-2.0078-0.375-4.3242 1.1641-5.8633 1.5391-1.5391 3.8555-1.9961 5.8633-1.1641 2.0117 0.83203 3.3203 2.793 3.3203 4.9688-0.003906 2.9688-2.4102 5.375-5.3789 5.3828zm5.8789-26.121s-0.53125 0.19141-0.53125 0.5v4.2695h0.003907c0 2.957-2.3984 5.3555-5.3555 5.3555s-5.3555-2.3984-5.3555-5.3555v-4.2695c0.046875-4.8594 3.1914-9.1445 7.8086-10.652 3.1914-1.0625 5.3008-4.0977 5.1914-7.457-0.21484-4.1094-3.5781-7.3438-7.6914-7.4023-2.9102 0.015626-5.5547 1.6914-6.8086 4.3203-1.2852 2.6641-4.4844 3.7852-7.1484 2.5-2.668-1.2852-3.7852-4.4844-2.5-7.1484 1.4766-3.0938 3.793-5.7109 6.6914-7.5469 2.8945-1.8359 6.25-2.8203 9.6758-2.8359h0.10938c4.7656 0.03125 9.332 1.8945 12.754 5.2109 3.4219 3.3125 5.4297 7.8203 5.6094 12.582 0.13281 3.9258-1.0078 7.793-3.25 11.023-2.2422 3.2266-5.4648 5.6445-9.1914 6.8945z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
