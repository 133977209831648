export default {
  addTeammate (email, role) {
    return axios.post('/users/add-teammate', { email, role }).then(
      response => response.data
    )
  },

  getTeammates () {
    return axios.get('/users/get-teammates').then(
      response => response.data
    )
  },

  deleteTeammate (id) {
    return axios.delete(`/users/delete-teammate/${id}`).then(
      response => response.data
    )
  },

  updateTeammate (id, role) {
    return axios.put(`/users/update-teammate/${id}`, { role })
  },

  storeToken (expirationDate) {
    return axios.post('/users/account/api', { expirationDate }).then(
      response => response.data
    )
  },

  deleteToken () {
    return axios.delete('/users/account/api').then(
      response => response.data
    )
  },

  loadToken () {
    return axios.get('/users/account/api').then(
      response => response.data
    )
  },
  getGeneralChannels (type) {
    return axios.get('/users/account/get-general-channels', { params: { type } }).then(
      response => response.data
    )
  },

  testOneTimeCode (oneTimeCode) {
    return axios.post('/users/account/test-tfa', { oneTimeCode }).then(
      response => response.data
    )
  }
}
