<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 24 24"
       @click="$emit('click')">
    <path d="M23.0667 20V17.8059C23.0667 16.8725 22.4315 16.0592 21.5265 15.8332L18.1561 14.9904C17.8515 14.9143 17.6389 14.639 17.5802 14.2219L17.5861 13.6415C17.6543 13.1362 17.9057 12.5638 18.3164 12.1138C19.6519 10.6493 19.6519 9.48452 19.6519 8.15794C19.6514 5.86667 17.8534 4.04132 15.6 4C15.1442 4.00836 14.707 4.08971 14.2996 4.23315" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    <path d="M15 20V17.8059C15 16.8725 14.4045 16.0592 13.5561 15.8332L10.3963 14.9904C10.1107 14.9143 9.91147 14.639 9.8564 14.2219L9.862 13.6415C9.92593 13.1362 10.1616 12.5638 10.5466 12.1138C11.7987 10.6493 11.7987 9.48452 11.7987 8.15794C11.7982 5.86667 10.1126 4.04132 8 4C5.8874 4.04132 4.2018 5.86667 4.2018 8.15744C4.2018 9.48402 4.2018 10.6488 5.45387 12.1133C5.83887 12.5633 6.07453 13.1357 6.11887 13.6823L6.12447 14.2627C6.08853 14.639 5.88927 14.9138 5.60367 14.9899L2.44387 15.8327C1.59547 16.0597 1 16.873 1 17.8059V20" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </svg>
</template>

<script>
import iconMixin from './iconMixin.js'

export default {
  mixins: [iconMixin]
}
</script>
